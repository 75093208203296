import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import ROUTES from '../../Configs/Routes'
import { Link, NavLink, useLocation } from 'react-router-dom'

const Header = () => {
  const token = localStorage.getItem('token');
  const location = useLocation();

  return (
    <>
      <Navbar expand="md" className="mainNavbar py-3">
        <Container>
          <Navbar.Brand>
            <Link to={ROUTES.HOME} >
              <img src={'/images/Logo1.png'} alt='Logo' className='headerLogo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {location.pathname !== ROUTES.LOGIN && location.pathname !== ROUTES.SIGNUP &&
              <Nav className="mx-auto">
                <Nav.Link to={ROUTES.HOME} as={NavLink}>Pricing</Nav.Link>
                <Nav.Link to={ROUTES.HOME} as={NavLink}>Support</Nav.Link>
                <Nav.Link to={ROUTES.HOME} as={NavLink}>About Us</Nav.Link>
              </Nav>
            }

            {token ?
              <Link to={ROUTES.DASHBOARD} className='btn themeBtn d-block my-2 my-md-0 mx-auto me-md-2'>Dashboard</Link>
              : <>
                {
                  location.pathname !== ROUTES.SIGNUP &&
                  <Link to={ROUTES.SIGNUP} className='btn themeBtn d-block my-2 my-md-0 mx-auto me-md-2'>Register</Link>
                }
                {location.pathname !== ROUTES.LOGIN &&
                  <Link to={ROUTES.LOGIN} className={'btn themeBtn d-block my-2 my-md-0 mx-auto' + (location.pathname !== ROUTES.SIGNUP ? ' headerBtn  mx-md-2' : '  me-md-2')}>Login</Link>
                }
              </>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header