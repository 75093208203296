import React, { useContext, useState } from "react";
import moment from "moment";
import { Button, Spinner, Table } from "react-bootstrap";
import { LuRefreshCw } from "react-icons/lu";
import MainContext from "../../Configs/Context/MainContext";

export default function FxAccountTable() {
  const context = useContext(MainContext);

  let status;
  if (context?.userFxData?.status === 'active') {
    status = 'Approved';
  } else if (context?.userFxData?.status === 'PENDING') {
    status = 'Pending';
  } else if (context?.userFxData?.status === 'inactive') {
    status = 'Rejected';
  } else {
    status = '';
  }

  return (
    <div>
      {context?.loading?.fxData ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <Table responsive className="forexTable mb-5">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Contact Email</th>
                <th>Broker </th>
                <th>Server name</th>
                <th>Forex Account No.</th>
                <th>Forex Password <p style={{ fontSize: '12px' }}>(Trading Password)</p></th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{context?.userFxData?.name}</td>
                <td>{context?.userFxData?.mobile}</td>
                <td>{context?.userFxData?.contactEmail}</td>
                <td>{context?.userFxData?.broker}</td>
                <td>{context?.userFxData?.serverUrl}</td>
                <td>{context?.userFxData?.accountNumber}</td>
                <td>{context?.userFxData?.fxPass}</td>
                {/* <td style={{ color: '#00C64F' }}>Approved</td> */}
                <td style={{ color: status === 'Pending' ? '#FFA800' : status === 'Approved' ? '#00C64F' : status === 'Rejected' ? 'red' : '' }}>{status}</td>
              </tr>
            </tbody>
          </Table>

          {/* {!bodyData?.length === 0 ?
            <Table responsive className="forexTable mb-5">
              <thead style={{ position: "sticky", top: "0" }}>
                <CustomTableHead headData={headData} />
              </thead>

              <tbody>
                {bodyData &&
                  bodyData.length > 0 &&
                  bodyData?.map((values) => {
                    return <CustomTableRow values={values} />;
                  })}
              </tbody>
            </Table>
            :
            <p className="text-center mb-5">No data found</p>
          } */}
        </div>
      )
      }
    </div >
  );
}
// function CustomTableHead({ headData }) {
//   return (
//     <tr>
//       <>
//         {headData?.map((data, id) => (
//           <th key={id}>{data}</th>
//         ))}
//       </>
//     </tr>
//   );
// }

// function CustomTableRow({ values }) {
//   return (
//     <tr>
//       <>
//         <td style={{ minWidth: "60px" }}>{values?.profit}</td>
//         <td style={{ minWidth: "60px" }}>{values?.symbol}</td>
//         <td style={{ minWidth: "60px" }}>{values?.side === "Sell" ? "Short" : "Long"}</td>
//         <td style={{ minWidth: "60px" }}>{values?.fuelFee?.toFixed(2)}</td>
//         <td style={{ minWidth: "60px" }}>{moment(values?.orderTime).format("DD-MM-YYYY HH:mm:ss")}</td>
//       </>
//     </tr>
//   );
// }
