import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { LuRefreshCw } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import AccountOptions from './AccountOptions';
import OpenTradeTable from '../ForexAccount/openComponent';
import CustomTableComponentForex from '../ForexAccount/tableComponent';
import ApiConfig from '../../Configs/ApiConfig';
import Axios from 'axios';
import DashboardChart from './DashboardChart';
import MainContext from '../../Configs/Context/MainContext';
import CustomModal from '../../Components/CustomModal/CustomModal';
import AlertModal from './AlertModal';
import ConnectAccount from './ConnectAccount';

const headDataOpen = ["", "Currency", "Lot Size", "Entry Price", "Mark Price", "PNL", "Open Date & Time",];
const headDataRecent = ["Profit", "Fuel Fees", "Currency", "Trade Type", "Date & Time"];

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const context = useContext(MainContext);
  const [selectedTable, setSelectedTable] = useState("open");
  const [recentData, setRecentData] = useState([]);
  const [openData, setOpenData] = useState([]);
  const [openLoad, setOpenLoad] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState({
    formSubmit: false,
    historyData: false,
  });

  useEffect(() => {
    if (token && context?.level2Pass) {
      getOpenTrades();
      getTradeHistory();
    }
  }, [token, context?.level2Pass, sortOrder]);

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getOpenTrades = async () => {
    try {
      setOpenLoad(true)
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getOpenTrades,
        headers: { token: token },
        params: {
          sortType: sortOrder,
          sortKey: "PNL",
        },
      });
      setOpenLoad(false)
      setOpenData(res.data?.result[0]?.data[0]?.openOrdersArray);
      console.log(res.data?.result[0]?.data[0]?.openOrdersArray);
    } catch (err) {
      setOpenLoad(false)
    }
  };

  const getTradeHistory = async () => {
    try {
      setLoading({ ...loading, historyData: true });
      const res = await Axios({
        method: "GET",
        url: ApiConfig.tradeHistory,
        headers: { token: token },
      });
      setLoading({ ...loading, historyData: false });
      setRecentData(res.data.result[0].data);
    } catch (err) {
      setLoading({ ...loading, historyData: false });
    }
  };

  const iconRotate = () => {
    // setIsRotated(!isRotated);
    context?.getAssetDetails();
  };

  useEffect(() => {
    let pass = localStorage.getItem("level2pass");
    if (token && pass) {
      getOpenTrades();
      getTradeHistory();
    }
  }, [token]);

  return (
    <>
      <Button className='refreshBtn mb-4' onClick={() => iconRotate()}> <LuRefreshCw color='#3DACF4' fontSize={'22px'} /> Refresh</Button >
      <Row className='gy-4 mb-4'>
        <Col sm={6} md={3}>
          <div className='dashDataDiv'>
            <p>Today's P/L</p>
            <h3>
              {context?.loading?.fuelFee ?
                <Spinner style={{ width: '25px', height: '25px', borderWidth: '2px' }} />
                : `$ 00`
              }
            </h3>
          </div>
        </Col>
        <Col sm={6} md={3}>
          <div className='dashDataDiv'>
            <p>Total P/L</p>
            <h3>
              {context?.loading?.fuelFee ?
                <Spinner style={{ width: '25px', height: '25px', borderWidth: '2px' }} />
                : `$ 00`
              }
            </h3>
          </div>
        </Col>
        <Col sm={6} md={3}>
          <div className='dashDataDiv'>
            <p style={{ color: '#3DACF4' }}>Exchange Balance</p>
            <h3>
              {context?.loading?.fuelFee ?
                <Spinner style={{ width: '25px', height: '25px', borderWidth: '2px' }} />
                : `$ ${context?.userFxData?.accountBalance ? context?.userFxData?.accountBalance?.toFixed(4) : "00"}`
              }
            </h3>
          </div>
        </Col>
        <Col sm={6} md={3}>
          <div className='dashDataDiv'>
            <p style={{ color: '#3DACF4' }}>My Fuel Balance</p>
            <h3>
              {context?.loading?.fuelFee ?
                <Spinner style={{ width: '25px', height: '25px', borderWidth: '2px' }} />
                : `$ ${context?.assetCount?.fuelAccountBalance ? context?.assetCount.fuelAccountBalance?.total?.toFixed(4) : "00"}`
              }
            </h3>
          </div>
        </Col>
      </Row>

      <div className='dashLinks'>
        <Button onClick={() => navigate(ROUTES.WALLET, { state: { tabName: 'deposit' } })}>Deposit</Button>
        <Button onClick={() => navigate(ROUTES.WALLET, { state: { tabName: 'withdraw' } })}>Withdraw</Button>
        <Button onClick={() => navigate(ROUTES.WALLET, { state: { tabName: 'transfer' } })}>Transfer</Button>
        <Button onClick={() => navigate(ROUTES.WALLET, { state: { tabName: 'assetDetails' } })}>History</Button>
      </div>

      {!context?.level2Pass && <ConnectAccount />}

      {(context?.hasFuelFee && context?.level1Pass && context?.level2Pass) ?
        <>
          <div className='dashboardChart my-4'>
            <DashboardChart />
          </div>

          <div className="tradeTableBtns d-flex gap-3 mb-3">
            <Button className={selectedTable === "open" && 'active'} onClick={() => { setSelectedTable("open"); }}>Open Trade's</Button>
            <Button className={selectedTable === "recent" && 'active'} onClick={() => { setSelectedTable("recent"); }}>Trade History </Button>
          </div>
          <div>
            {selectedTable === "open" ? (
              <OpenTradeTable
                headData={headDataOpen}
                bodyData={openData}
                titleText={"Open Positions"}
                loading={openLoad}
                getOpenTrades={getOpenTrades}
                handleSort={handleSort}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            ) : (
              <CustomTableComponentForex
                headData={headDataRecent}
                bodyData={recentData}
                titleText={"Trade History "}
                loading={loading.historyData}
                getTradeHistory={getTradeHistory}
              />
            )}
          </div>
        </>
        :
        <AccountOptions />
      }

      <CustomModal display={context?.planAlert} handleClose={() => context?.setPlanAlert(false)} size='md' className='alertModal' centered>
        <AlertModal
          handleClose={() => context?.setPlanAlert(false)}
          title={"Plan Alert!"}
          desc={<>You Haven't Purchase Any Plane Yet.<br /> Please Purchase Minimum 1 Year Plan To Access The Service.</>}
          confirmationHandler={() => { context?.setPlanAlert(false); context?.setPurchaseOpen(true); }}
        // loading={loadingTrans}
        />
      </CustomModal>
    </>
  )
}

export default Dashboard