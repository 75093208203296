const HOME = '/';
const LOGIN = '/login';
const SIGNUP = '/signup';
const SIGNUP_VERIFY = '/signup-otp-verify';
const FORGET_PWD = '/forget-password'
const FORGET_VERIFY = '/forget-password-otp-verify';
const RESET_PASSWORD = '/reset-password';
const DASHBOARD = '/dashboard';
const ACCOUNT = '/account';
const WALLET = '/fuel-wallet';
const NETWORK = '/network';
const TERMS = '/terms-and-conditions';
const PRIVACY = '/privacy-policy';
const SERVICE = '/user-service';
const SECURITY = '/security-center';

const ROUTES = {
    HOME,
    LOGIN,
    SIGNUP,
    SIGNUP_VERIFY,
    FORGET_PWD,
    FORGET_VERIFY,
    RESET_PASSWORD,
    DASHBOARD,
    ACCOUNT,
    WALLET,
    NETWORK,
    TERMS,
    PRIVACY,
    SERVICE,
    SECURITY,
}

export default ROUTES;