import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import ROUTES from "./Configs/Routes";
import ContextState from './Configs/Context/ContextState';
import Dashboard from "./Pages/Dashboard";
import ForexTrade from "./Pages/ForexAccount";
import FuelWallet from "./Pages/FuelWallet";
import PageNotFound from "./Pages/PageNotFound";
import Network from "./Pages/Network";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import SignupOtpVerifiy from "./Pages/Auth/SignupOtpVerifiy";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import ForgetOtpVerify from "./Pages/Auth/ForgetOtpVerify";
import UserLayout from "./Components/UserComponents/UserLayout";
import Home from "./Pages/Home";
import SecurityCenter from "./Pages/SecurityCenter";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ContextState>
          <Routes>
            <Route path={'/*'} element={<PageNotFound />} />
            <Route element={<Layout />}>
              <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
              <Route path={ROUTES.ACCOUNT} element={<ForexTrade />} />
              <Route path={ROUTES.WALLET} element={<FuelWallet />} />
              <Route path={ROUTES.NETWORK} element={<Network />} />
              <Route path={ROUTES.SECURITY} element={<SecurityCenter />} />
            </Route>
            <Route element={<UserLayout />}>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.SIGNUP} element={<Signup />} />
              <Route path={ROUTES.SIGNUP_VERIFY} element={<SignupOtpVerifiy />} />
              <Route path={ROUTES.FORGET_PWD} element={<ForgetPassword />} />
              <Route path={ROUTES.FORGET_VERIFY} element={<ForgetOtpVerify />} />
            </Route>
          </Routes>

          <ToastContainer />
        </ContextState>
      </BrowserRouter>
    </div>
  );
}

export default App;
