import ROUTES from "./Routes";
import { FaUserTie, FaUsers, FaWallet } from "react-icons/fa";
import { HiMiniSquares2X2 } from "react-icons/hi2";
import { MdSecurity } from "react-icons/md";
// import { PiSquaresFourDuotone } from "react-icons/pi";

const MAIN = [
  {
    LINK: ROUTES.DASHBOARD,
    TITLE: "Dashboard",
    ICON: <HiMiniSquares2X2 />,
  },
  {
    LINK: ROUTES.ACCOUNT,
    TITLE: "My Account",
    ICON: <FaUserTie />,
  },
  {
    LINK: ROUTES.WALLET,
    TITLE: "Fuel Wallet",
    ICON: <FaWallet />,
  },
  {
    LINK: ROUTES.NETWORK,
    TITLE: "My Network",
    ICON: <FaUsers />,
  },
  {
    LINK: ROUTES.SECURITY,
    TITLE: "Security",
    ICON: <MdSecurity />,
  },
];

const MENU = {
  MAIN,
};

export default MENU;
