import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../../Assets/Css/user.css';
import ROUTES from '../../Configs/Routes';

const UserLayout = () => {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && location.pathname === ROUTES.LOGIN && location.pathname === ROUTES.SIGNUP && location.pathname === ROUTES.SIGNUP_VERIFY && location.pathname === ROUTES.FORGET_PWD && location.pathname === ROUTES.FORGET_VERIFY) {
      navigate(ROUTES.DASHBOARD);
    } else { }
  }, [token])

  return (
    <>
      <div className='userWrapper'>
        <Header />
        <Outlet />
        {location.pathname === ROUTES.HOME &&
          <Footer />
        }
      </div>
    </>
  )
}

export default UserLayout