import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './auth.css'
import ApiConfig from '../../Configs/ApiConfig';
import { useNavigate } from 'react-router-dom';
import MainContext from '../../Configs/Context/MainContext';
import { Button, Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import ROUTES from '../../Configs/Routes';

const SignupOtpVerifiy = () => {
  const token = localStorage.getItem('token');
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ otp: "" });
  const [errors, setErrors] = useState({ otp: "" });
  const [isLoading, setLoader] = useState(false);
  const [reSendOtpLoader, setReSendOtpLoader] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formData.otp === "") {
      setErrors({ ...formData, otp: 'Please enter OTP.' });
      return;
    } else {
      setErrors({ ...formData, otp: '' });
    }

    try {
      setLoader(true);

      const res = await axios({
        method: "PUT",
        url: ApiConfig.verifyOtpEmail,
        data: {
          otp: formData.otp.toString(),
          email: window.localStorage.getItem("email"),
        },
      });

      if (res.data.statusCode === 200) {
        setLoader(false);
        toast.success("Account created successfully.");
        window.localStorage.setItem("token", res.data.result);
        context?.setJustLogin(true);
        navigate(ROUTES.DASHBOARD);
        // navigate({
        //   pathname: ROUTES.LOGIN,
        //   state: {
        //     email: res.data.result.email
        //     , otp: formData.otp.toString(), userI: res.data.result._id
        //     , otpVerificationStatus: res.data.result.otpVerificationStatus
        //   },
        // });
      } else {
        setLoader(false);
        toast.warn(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.message);
      }
    }
  };

  const resetotphandle = async (values) => {
    setReSendOtpLoader(true);
    try {

      const res = await axios({
        method: "PUT",
        url: ApiConfig.signupResend,
        data: {
          email: localStorage.getItem("email"),
        },
      });
      if (res.data.statusCode === 200) {
        toast.success("OTP has been sent to your registered email address.");
        setReSendOtpLoader(false);
        context?.setEndtime(moment().add(2, "m").unix());
      } else {
        setReSendOtpLoader(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
      setReSendOtpLoader(false);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.message);
      }
    }
  };
  const email = window.localStorage.getItem("email");
  const minute = context?.timeLeft?.minutes?.toString();
  const second = context?.timeLeft?.seconds?.toString();

  useEffect(() => {
    if (token) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [token])

  return (
    <>
      <div className="authDiv">
        <div className="loginDiv">
          <img src="/images/logo.png" alt="logo" className="authLogo" />
          {/* {showLogin ? ( */}

          <Form onSubmit={handleFormSubmit}>
            <p className="text-center my-3">Please enter the 6-digit code sent to your registered email address.</p>

            <div className="mb-3">
              <label>Verification Code <span style={{ color: "red" }}>*</span></label>
              <Form.Control placeholder="Enter your OTP" type="number" minLength={6} maxLength={6} value={formData.otp} onChange={(e) => { setFormData({ ...formData, otp: e.target.value }) }} onKeyDown={(event) => { if (event?.key === "-" || event?.key === "+") { event.preventDefault(); } }} />
              <div className='d-flex justify-content-between mt-1'>
                <p style={{ fontSize: "12px", color: 'red', }}>{errors.otp || ''}</p>
                <div>
                  {context?.timeLeft?.minutes > 0 || context?.timeLeft?.seconds > 0 ? (
                    <>
                      <p style={{ color: "red", fontSize: "12px", }}>
                        {minute.length > 1 ? minute : "0" + minute} : {second.length > 1 ? second : "0" + second}
                      </p>
                    </>
                  ) : (
                    <>
                      <Button className='resendOtp' onClick={() => { resetotphandle(); }} disabled={context?.timeLeft && context?.timeLeft.seconds > 0 || reSendOtpLoader}>
                        {reSendOtpLoader ? <div></div> : "Resend OTP "}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Button type="submit" className="loginBtn mt-4" disabled={isLoading}>
              Submit {isLoading && <Spinner size="sm" />}
            </Button>

          </Form>
        </div>
      </div>
    </>
  )
}

export default SignupOtpVerifiy