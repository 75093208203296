import React, { useState } from "react";
import moment from "moment";
import { Button, Spinner, Table } from "react-bootstrap";
import { LuRefreshCw } from "react-icons/lu";

export default function CustomTableComponentForex(props) {
  const { headData, bodyData } = props;

  return (
    <div>
      {props.loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "5%" }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          {/* <div className="d-flex align-items-center justify-content-between mb-4">
            <h5>{titleText}</h5>
            <div>
              {!props.loading && (
                <Button className='refreshBtn' onClick={() => { props.getTradeHistory(); }}><LuRefreshCw color='#3DACF4' fontSize={'22px'} /> Refresh</Button>
              )}
            </div>
          </div> */}

          {!bodyData?.length === 0 ? (
            <Table responsive className="forexTable">
              <thead style={{ position: "sticky", top: "0" }}>
                <CustomTableHead headData={headData} />
              </thead>

              <tbody>
                {bodyData &&
                  bodyData.length > 0 &&
                  bodyData?.map((values) => {
                    return <CustomTableRow values={values} />;
                  })}
              </tbody>
            </Table>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>No data found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
function CustomTableHead({ headData }) {
  return (
    <tr>
      <>
        {headData?.map((data, id) => (
          <th key={id}>{data}</th>
        ))}
      </>
    </tr>
  );
}

function CustomTableRow({ values }) {
  return (
    <tr>
      <>
        <td style={{ minWidth: "60px", color: values?.profit > 0 ? "#00C64F" : "#FF4026", }}>{values?.profit}</td>
        <td style={{ minWidth: "60px", color: values?.fuelFee > 0 ? "#00C64F" : "#FF4026", }}>{values?.fuelFee?.toFixed(2)}</td>
        <td style={{ minWidth: "60px" }}>{values?.symbol}</td>
        <td style={{ minWidth: "60px" }}>{values?.side === "Sell" ? "Sell" : "Buy"}</td>
        <td style={{ minWidth: "60px" }}>{moment(values?.orderTime).format("DD-MM-YYYY HH:mm:ss")}</td>
      </>
    </tr>
  );
}
