import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Col, Row } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import ApiConfig from "../../Configs/ApiConfig";
import { PiCopySimpleLight } from "react-icons/pi";

const Deposit = () => {
  const token = localStorage.getItem("token");
  const user = useContext(MainContext);
  const [address, setAddress] = useState("");
  const exchangeBalance = user?.exchangeBalance?.allExchangeTotal;

  const getDeposit = async (values) => {
    const res = await Axios({
      method: "GET",
      url: ApiConfig.deposit,
      headers: { token: token },
    });

    if (res.data.statusCode === 200) {
      setAddress(res.data.result.depositAddress);
    }
  };

  useEffect(() => {
    if (token) {
      getDeposit();
    }
  }, [token]);

  const handleCopy = () => {
    toast.success("Copied Successfully");
  };

  return (
    <>
      <div>
        <Row className="depositeDiv align-items-center mb-4">
          <Col md={8} xl={7} xxl={6} className="mb-4 mb-md-0">
            <h6 className="mb-4">Chain : <span className="text-white py-1 px-2" style={{ background: '#3DACF4', borderRadius: '10px' }}>BEP20</span></h6>
            <div className="mb-3">
              <h6>USDT Deposit Address</h6>
              <div className="depositesAddress">{address || '-'} <span><CopyToClipboard text={address} onCopy={() => handleCopy()}><PiCopySimpleLight /></CopyToClipboard></span></div>
            </div>
            <h6>Current Balance: $ {exchangeBalance ? exchangeBalance.toFixed(4) : 0.0}</h6>
            {exchangeBalance < 20 &&
              <p style={{ fontSize: '13px', color: 'red' }}>You need at least 20 USDT to Proceed.</p>
            }
          </Col>
          <Col md={4} xl={4} xxl={4}>
            <div className="depositeQr">
              <QRCode value={address} />
            </div>
          </Col>
        </Row>

        <div>
          <h5>Precautions:</h5>
          <ol className="ps-3" style={{ color: '#838383', fontSize: '14px' }}>
            <li>Please do not deposit any non-USDT assets to the address above.</li>
            <li>Ensure you use BEP20 to deposit, or assets might be lost.</li>
            <li>The deposit may take a short while to arrive.</li>
            <li>Funds may not be withdrawn from inactive accounts.</li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Deposit;
