import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../Configs/Routes';
import MENU from '../Configs/sidebarMenu';
import MainContext from '../Configs/Context/MainContext';
import { FaTimes } from 'react-icons/fa';

const Sidebar = () => {

  // const controllers = [];
  const location = window.location.href;
  const context = useContext(MainContext);
  const navigate = useNavigate();
  const [active, setActive] = useState('');

  const handleSidebar = () => {
    context.setSidebar(false);
  }

  // const handleToggle = (e) => {
  //   let classList = e.currentTarget.parentElement.classList;
  //   if (classList.contains('openToggle')) {
  //     classList.remove('openToggle')
  //   } else {
  //     classList.add('openToggle')
  //   };
  // }

  const handleLogout = () => {
    localStorage.clear();
    navigate(ROUTES.LOGIN);
  }

  return (
    <>
      <>
        <Button className='slideCloseBtn' onClick={handleSidebar}><FaTimes /></Button>
        <div className='dashboardLogo'>
          <Link to={ROUTES.DASHBOARD} onClick={() => setActive(0)}>
            <img src='/images/logo.png' alt='Logo' crossOrigin='anonymous' />
          </Link>
        </div>
        <div className='sidebarScrollDiv'>
          <ul className='sidebarMenu'>

            {MENU.MAIN.map((item, index) => {
              return (
                // <li className={(index === active || location.includes(item.LINK)) ? 'sidebarMenuItem activeMenu' : 'sidebarMenuItem'} key={index} onClick={() => setActive(index)} >
                <li className={(location.includes(item.LINK)) ? 'sidebarMenuItem activeMenu' : 'sidebarMenuItem'} key={index} onClick={() => setActive(index)} >
                  <Link to={item.LINK} id={index}><span>{item.ICON}</span>{item.TITLE}</Link>
                </li>
              )
            })}
            <li className='logoutBtn'>
              <Button onClick={handleLogout}>Logout</Button>
            </li>
          </ul>
        </div>
      </>
    </>
  )
}

export default Sidebar