import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ROUTES from '../../Configs/Routes'
import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaPhone, FaXTwitter } from 'react-icons/fa6'
import { FaMapMarkerAlt, FaTelegramPlane } from 'react-icons/fa'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

const Footer = () => {
  return (
    <>
      <div className='newsletterSec'>
        <Container>
          <Row className='align-items-center'>
            <Col lg={6}>
              <h6>Subscribe Newsletter</h6>
              <h2>Signup Our Newsletter</h2>
            </Col>
            <Col lg={6}>
              <div className='newsletterInput'>
                <Form.Control type='email' placeholder='Enter Your Email Address' />
                <Button><HiOutlineArrowNarrowRight /></Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='footerSec'>
        <Container>
          <Row className='justify-content-between g-4'>
            <Col lg={4} xxl={3}>
              <Link to={ROUTES.HOME} >
                <img src={'/images/Logo1.png'} alt='logo' className='footerLogo' />
              </Link>

              <p className='my-3'>Forex.com stands out from the crowd because of our broad and inclusive ecosystem.</p>

              <div className='footerSocials d-flex flex-wrap gap-3'>
                <a href='#' target='_blank'><FaFacebookF /></a>
                <a href='#' target='_blank'><FaXTwitter /></a>
                <a href='#' target='_blank'><FaLinkedinIn /></a>
                <a href='#' target='_blank'><FaInstagram /></a>
                <a href='#' target='_blank'><FaTelegramPlane /></a>
              </div>
            </Col>
            <Col sm={6} lg={3} xxl={2}>
              <h5 className='footerHead'>Quick Link</h5>
              <ul className='m-0'>
                <li><Link to={ROUTES.HOME}>Home</Link></li>
                <li><Link to={ROUTES.HOME}>Features</Link></li>
                <li><Link to={ROUTES.HOME}>Pricing</Link></li>
                <li><Link to={ROUTES.HOME}>Affiliate Program</Link></li>
                <li><Link to={ROUTES.HOME}>Contact</Link></li>
                <li><Link to={ROUTES.HOME}>Privacy Policy</Link></li>
                <li><Link to={ROUTES.HOME}>Terms of Service</Link></li>
              </ul>
            </Col>
            <Col sm={6} lg={4} xxl={3}>
              <h5 className='footerHead'>Quick Contact</h5>
              <div className='footerContact d-flex gap-2 mb-3'>
                <p className='contactIcon'><FaEnvelope /></p>
                <div>
                  <h6>Email Address</h6>
                  <p>connect@forex.com</p>
                </div>
              </div>
              <div className='footerContact d-flex gap-2 mb-3'>
                <p className='contactIcon'><FaMapMarkerAlt /></p>
                <div>
                  <h6>Our Location</h6>
                  <p>2nd Floor, Ventu, 589-595, Sector 29, Delhi, 122002</p>
                </div>
              </div>
              <div className='footerContact d-flex gap-2'>
                <p className='contactIcon'><FaPhone /></p>
                <div>
                  <h6>Our Phone</h6>
                  <p>+91 12345 67890</p>
                </div>
              </div>
            </Col>
          </Row>
          <img src='/images/user/footerLine.png' alt='' className='w-100 my-3' />
          <p className='text-center'>Copyright &copy; 2023 - 2024 Forex.com.</p>
        </Container>
      </div>
    </>
  )
}

export default Footer