import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

const AlertModal = ({ handleClose, title, desc, confirmationHandler }) => {
  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <div>
        <Modal.Body className='p-2 px-sm-5'>
          <img src='/images/icons/alert.png' alt='alert' className='mb-3' />
          <h3>{title}</h3>
          <p>{desc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' type='submit' className='modalSubmit' onClick={confirmationHandler}>Buy Now</Button>
        </Modal.Footer>
      </div>
    </>
  )
}

export default AlertModal