import React, { useState, useContext } from "react";
import { FaTelegramPlane, FaInstagram, FaFacebookF, FaYoutube, } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from "react-share";
import { Button } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import { PiCopySimpleLight } from "react-icons/pi";
import ROUTES from "../../Configs/Routes";

const ReferFriends = (props) => {
  const [copy, setCopy] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const user = useContext(MainContext);

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  const handleCopy2 = () => {
    setCopy2(true);
    setTimeout(() => {
      setCopy2(false);
    }, 2000);
  };


  function shareOnInstagram(code) {
    const instagramURL = "https://www.instagram.com/";
    const caption = `Join me on my app with my invitation code: ${code}`;
    const encodedCaption = encodeURIComponent(caption);
    const shareURL = `${instagramURL}create/story?text=${encodedCaption}`;
    window.open(shareURL, "_blank");
  }
  const shareUrl = `${window.location.origin}/register?inviteCode=${user?.profile?.referralCode}`;
  const title = "";
  const description = "Hey there! I trust Olympus and their state-of-the-art crypto trading bot. Join Olympus through my referral link and take advantage of their advanced trading technology. Participate in the Olympus Refer and Earn program now to win exciting rewards. Start your crypto trading journey with Olympus today!";
  const imageUrl =
    "https://ci6.googleusercontent.com/proxy/WjmrbZyHRO0shx-Tf8uE-hXeIcrDnY-tPtcaJGLmzBpjaTeoBc64CBxJvy1MHosFpkjcprNpDqsqIErQKFTyF0vzI09FXIpbiT7V0DUZHB6AfITTUlDV8p4QQ4BQSay3m_sqsT9RTzWI8ZwB=s0-d-e1-ft#https://res.cloudinary.com/doafqjqkw/image/upload/v1683391821/Group_1171276503_t7xjkm.png";

  return (
    <>
      <div className="referralDiv d-flex flex-wrap justify-content-between gap-3 mb-4">
        <div>
          <div className="d-flex flex-wrap gap-3 mb-3">
            <div>
              <h6>My Referral Link</h6>
              <div className="depositesAddress">
                {window.location.origin + `${ROUTES.SIGNUP}?inviteCode=${user?.profile?.referralCode || ""}`}
                <span>
                  <CopyToClipboard text={window.location.origin + `${ROUTES.SIGNUP}?inviteCode=${user?.profile?.referralCode || ""}`} onCopy={() => handleCopy()}>
                    <PiCopySimpleLight />
                  </CopyToClipboard>
                </span>
              </div>
              {/* {copy === true && <p className="text-end" style={{ color: '#3DACF4', fontSize: '12px' }}>Copied</p>} */}
            </div>
            <div>
              <h6>My Invitation code</h6>
              <div className="depositesAddress">
                {user?.profile?.referralCode || '-'}
                <span>
                  <CopyToClipboard text={user?.profile?.referralCode} onCopy={() => handleCopy2()}>
                    <PiCopySimpleLight />
                  </CopyToClipboard>
                </span>
              </div>
              {/* {copy2 === true && <p className="text-end" style={{ color: '#3DACF4', fontSize: '12px' }}>Copied</p>} */}
            </div>
          </div>

          <div className="referShare d-flex gap-4">
            <TelegramShareButton url={shareUrl} title={title} media={imageUrl} body={description}>
              <img src={'/images/icons/telegram.png'} alt="telegram" />
            </TelegramShareButton>

            <TwitterShareButton url={shareUrl} title={title} media={imageUrl} body={description}>
              <img src={'/images/icons/twitter.png'} alt="twitter" />
            </TwitterShareButton>

            <img src={'/images/icons/instagram.png'} alt="instagram" onClick={() => shareOnInstagram(user?.profile?.referralCode)} />

            <FacebookShareButton url={shareUrl} description={description}>
              <img src={'/images/icons/facebook.png'} alt="facebook" />
            </FacebookShareButton>
          </div>
        </div>
        <div className="referQr">
          <QRCode value={window.location.origin + `${ROUTES.SIGNUP}`?.concat(user?.profile?.referralCode)} />
          <p className="text-center text-secondary" style={{ fontSize: '14px' }}>Scan Here!</p>
        </div>
      </div>
    </>
  );
};

export default ReferFriends;
