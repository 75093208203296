import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MainContext from '../../Configs/Context/MainContext';
import ApiConfig from '../../Configs/ApiConfig';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoIosLock } from 'react-icons/io';
import { BiSolidEnvelope } from 'react-icons/bi';
import { MdCheckCircle } from 'react-icons/md';

const LoginPwdChange = () => {
  const token = localStorage.getItem("token");
  const context = useContext(MainContext);
  const [loader, setLoader] = useState(false);
  const [getOtpLoader, setGetOtpLoader] = useState(false);
  const [isOTPloader, setOTPloader] = useState(false);
  const [showOtpButton, setShowOtpButton] = useState(false);
  const [verify, setVerify] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const userEmail = context?.profile?.email ? context?.profile?.email : null;
  const [formData, setFormData] = useState({
    password: "",
    newPass: "",
    confirmPass: "",
    otp: "",
  })
  const [errors, setErrors] = useState({
    password: "",
    newPass: "",
    confirmPass: "",
    otp: "",
  })

  const getOtp = async () => {
    if ((formData.password === "") && (formData.newPass === "") && (formData.confirmPass === "")) {
      setErrors({
        ...errors,
        password: "Password is required.",
        newPass: "New Password is required.",
        confirmPass: "Confirm Password is required.",
      });
      return;
    } else if (formData.password === "") {
      setErrors({ ...errors, password: "Password is required." });
      return;
    } else if (formData.newPass === "") {
      setErrors({ ...errors, newPass: "New Password is required." });
      return;
    } else if (formData.newPass.toString().length < 6 || formData.newPass.toString().length > 20) {
      setErrors({ ...errors, newPass: "Password must contain 6 to 20 character" });
      return;
    } else if (!formData.newPass.toString().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/)) {
      setErrors({ ...errors, newPass: "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character" });
      return;
    } else if (formData.confirmPass === "") {
      setErrors({ ...errors, confirmPass: "Confirm Password is required." });
      return;
    } else if (formData.newPass !== formData.confirmPass) {
      setErrors({ ...errors, confirmPass: "Passwords do not match." });
      return;
    } else {
      setErrors({
        password: "",
        newPass: "",
        confirmPass: "",
        otp: "",
      });
    }

    setGetOtpLoader(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.forgotPassword,
        params: {
          email: userEmail,
        },
      });

      if (res.data.statusCode === 200) {
        setGetOtpLoader(false);
        setShowOtpButton(true);
        context?.setEndtime(moment().add(2, "m").unix());
        toast.success("OTP sent successfully to your registered email address.");
      } else {
        setGetOtpLoader(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
      setGetOtpLoader(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
      else {
        toast.error("Something went wrong!");
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if ((formData.password === "") && (formData.newPass === "") && (formData.confirmPass === "") && (formData.otp === "")) {
      setErrors({
        ...errors,
        password: "Password is required.",
        newPass: "New Password is required.",
        confirmPass: "Confirm Password is required.",
        otp: "OTP is required",
      });
      return;
    } else if (formData.password === "") {
      setErrors({ ...errors, password: "Password is required." });
      return;
    } else if (formData.newPass === "") {
      setErrors({ ...errors, newPass: "New Password is required." });
      return;
    } else if (formData.newPass.toString().length < 6 || formData.newPass.toString().length > 20) {
      setErrors({ ...errors, newPass: "Password must contain 6 to 20 character" });
      return;
    } else if (!formData.newPass.toString().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/)) {
      setErrors({ ...errors, newPass: "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character" });
      return;
    } else if (formData.confirmPass === "") {
      setErrors({ ...errors, confirmPass: "Confirm Password is required." });
      return;
    } else if (formData.newPass !== formData.confirmPass) {
      setErrors({ ...errors, confirmPass: "Passwords do not match." });
      return;
    } else if (formData.otp === "") {
      setErrors({ ...errors, otp: "OTP is required." });
      return;
    } else {
      setErrors({
        password: "",
        newPass: "",
        confirmPass: "",
        otp: "",
      });
    }

    setLoader(true);
    setErrors({ ...errors, password: '', newPass: '', confirmPass: '', otp: '' });

    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.modifyPassword,
        headers: { token: token },
        data: {
          oldPassword: formData.password,
          newPassword: formData.newPass,
          confirmPassword: formData.confirmPass,
          otp: formData.otp.toString(),
        },
      });

      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);
        setShowOtpButton(false);
        context?.setEndtime(moment().add(0, "m").unix());
        setLoader(false);
        setVerify(true);
        setFormData({ ...formData, password: '', newPass: '', confirmPass: '', otp: '' });
      } else {
        setLoader(false);
        toast.warn(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
      }
      else {
        toast.error("Something went wrong!");
      }
    }
  };

  const resetotphandle = async () => {
    setOTPloader(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.forgotPassword,
        params: {
          email: userEmail,
        },
      });
      if (res.data.statusCode === 200) {
        toast.success("OTP has been sent to your registered email address.");
        setOTPloader(false);
        context?.setEndtime(moment().add(2, "m").unix());
      } else {
        setOTPloader(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
      setOTPloader(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
      else {
        toast.error("Something went wrong!");
      }
    }
  };

  const minute = context?.timeLeft?.minutes?.toString();
  const second = context?.timeLeft?.seconds?.toString();

  return (
    <>
      <Form className='passChangeForm' onSubmit={handleFormSubmit}>
        <div container>
          <div className='d-flex justify-content-center align-items-center gap-1 gap-sm-3 mb-2'>
            <div className='passChangeStep active'>
              <IoIosLock />
            </div>
            <img src='/images/pwdChangeLine.png' alt='' style={{ minWidth: '23%' }} />
            <div className={(showOtpButton || verify) ? 'passChangeStep active' : 'passChangeStep'}>
              <BiSolidEnvelope />
            </div>
            <img src='/images/pwdChangeLine.png' alt='' style={{ transform: 'rotateX(180deg)', minWidth: '23%' }} />
            <div className={verify ? 'passChangeStep active' : 'passChangeStep'}>
              <MdCheckCircle />
            </div>
          </div>
          <p className='mb-3' style={{ fontSize: '12px', color: '#3DACF4' }}>After Changing Your Password, Some Features Will Be Restricted For 48 Hours (Including Withdraw, Transfer, Recharge And Transaction)</p>

          <div className='mb-3'>
            <label>Old Password <span style={{ color: "red" }}>*</span></label>
            <InputGroup>
              <Form.Control type={showOldPassword ? "text" : "password"} placeholder="Please Enter your password" onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }} value={formData.password} />
              <InputGroup.Text>
                <div onClick={() => setShowOldPassword(!showOldPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                  {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </InputGroup.Text>
            </InputGroup>
            <p style={{ fontSize: "12px", color: 'red', }}>{errors.password || ''}</p>
          </div>

          <div className='mb-3'>
            <label>New Password <span style={{ color: "red" }}>*</span></label>
            <InputGroup>
              <Form.Control type={showNewPassword ? "text" : "password"} placeholder="Please Enter your password" onChange={(e) => { setFormData({ ...formData, newPass: e.target.value }) }} value={formData.newPass} />
              <InputGroup.Text>
                <div onClick={() => setShowNewPassword(!showNewPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </InputGroup.Text>
            </InputGroup>
            <p style={{ fontSize: "12px", color: 'red', }}>{errors.newPass || ''}</p>
          </div>

          <div className='mb-3'>
            <label>Confirm Password <span style={{ color: "red" }}>*</span></label>
            <InputGroup>
              <Form.Control type={showConfirmPassword ? "text" : "password"} placeholder="Confirm Password" onChange={(e) => { setFormData({ ...formData, confirmPass: e.target.value }) }} value={formData.confirmPass} minLength={8} maxLength={20} />
              <InputGroup.Text>
                <div onClick={() => setshowConfirmPassword(!showConfirmPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </InputGroup.Text>
            </InputGroup>
            <p style={{ fontSize: "12px", color: 'red', }}>{errors.confirmPass || ''}</p>
          </div>

          <div className='mb-3'>
            <label>Verification Code <span style={{ color: "red" }}>*</span></label>
            <InputGroup>
              <Form.Control type="number" placeholder="Enter Your OTP" onChange={(e) => { setFormData({ ...formData, otp: e.target.value }) }} value={formData.otp} minLength={6} maxLength={6} />
              <InputGroup.Text>
                {showOtpButton == false && (
                  <Button onClick={() => getOtp()}
                    disabled={getOtpLoader || formData.password === "" || errors.newPass || formData.newPass === "" || errors.confirmPass || formData.confirmPass === ""}
                  >
                    {getOtpLoader ? <Spinner size='sm' /> : "Send Code"}
                  </Button>
                )}
                {showOtpButton && (
                  <div>
                    {context?.timeLeft?.minutes > 0 || context?.timeLeft?.seconds > 0 ?
                      <p style={{ fontSize: "12px", color: 'red' }}>{minute.length > 1 ? minute : "0" + minute} : {second.length > 1 ? second : "0" + second}</p>
                      :
                      <Button onClick={() => { resetotphandle(); }} disabled={(context?.timeLeft && context?.timeLeft.seconds > 0) || isOTPloader}>
                        {isOTPloader ? <div></div> : "Resend Code"}
                      </Button>
                    }
                  </div>
                )}
              </InputGroup.Text>
            </InputGroup>
            <p style={{ fontSize: "12px", color: 'red', }}>{errors.otp || ''}</p>
          </div>

          <Button type="submit" className="submitBtn" disabled={loader}>
            SUBMIT {loader && <Spinner size="sm" />}
          </Button>
        </div>
      </Form >
    </>
  )
}

export default LoginPwdChange