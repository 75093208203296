const url = "https://node.fxline.xyz/api/v1";

const ApiConfig = {
  login: `${url}/user/user-login`,
  userSignUp: `${url}/signup`,
  userSendOtp: `${url}/user/user-Register-Send-Otp-Email`,
  userVerify: `${url}/user/user-Signup-verify-Otp-Email`,
  otpVerify: `${url}/user/user-Forgot-verify-Otp-Email`,
  verifyOtpEmail: `${url}/user/userRegisterVerifyOtpEmail`,
  reset: `${url}/user/user-Forgot-Password`,
  signupResend: `${url}/user/user-Signup-resend-Otp-Email`,
  forgotPassword: `${url}/user/user-Forgot-Password-Send-OTP`,
  userRegister: `${url}/user/userRegister`,
  viewMyProfile: `${url}/user/viewMyProfile`,
  editUserProfilePic: `${url}/user/user-editUserProfilePic`,
  getAllBanners: `${url}/banner/get-all-banners-of-web`,
  getTradingBotDetails: `${url}/buy/getTradingBotDetails`,
  shareMyReferralLinks: `${url}/user/shareMyReferralLinks`,
  numbersofCommunityMember: `${url}/user/communityMemberdashboard`,
  directReferral: `${url}/user/directReferralDashboard`,
  getActivationGainCount: `${url}/user/getActivationGainCount`,
  getAdvertismentForUser: `${url}/admin/getAdvertismentForUser`,
  buyPlanData: `${url}/buy/buyPlanData`,
  listExchange: `${url}/exchangeWallet/listExchange`,
  serverIPAddress: `${url}/exchangeWallet/serverIPAddress`,
  connectExchange: `${url}/exchangeWallet/connectExchange`,
  getVipMemberInterest: `${url}/buy/getVipMemberInterest`,
  exchangeWallet: `${url}/exchangeWallet/exchangeBalance`,
  referralSummary: `${url}/user/getDirectReffralSummary`,
  referralDetails: `${url}/user/my-team-list`,
  referralViewDetails: `${url}/user/my-team-view-list`,
  getTeamAccountSettings: `${url}/user/getTeamAccountSettings`,
  getTeamAccountDirectRefferal: `${url}/user/getTeamAccountDirectRefferal`,
  assetDetails: `${url}/assets/assetDetails`,
  deposit: `${url}/assets/deposit`,
  WithdrawAsset: `${url}/assetsTransaction/WithdrawAsset`,
  verifyTransaction: `${url}/assetsTransaction/verifyTransaction`,
  confirmAssetTransaction: `${url}/assetsTransaction/confirmAssetTransaction`,
  transferAsset: `${url}/assetsTransaction/transferAsset`,
  userdashboard: `${url}/user/userdashboard`,
  checkReceiverAccount: `${url}/assetsTransaction/checkReceiverAccount`,
  postTeamAccountDirectRefferal: `${url}/user/postTeamAccountDirectRefferal`,
  getTickers: `${url}/exchangeWallet/getTickers`,
  planDetails: `${url}/buy/getPlanDetails`,
  planHistory: `${url}/user/get-plan-purchase-history`,
  directReffrelCountBar: `${url}/user/directReffrelCountBar`,
  sendVerificationCode: `${url}/exchangeWallet/sendVerificationCode`,
  getRevenueDetails: `${url}/user/getRevenueDetails`,
  customer: `${url}/contactUs/keep-in-touch`,
  subscriber: `${url}/subscriber/user-subscribe`,
  listNotification: `${url}/notification/listNotification`,
  totalUsersCount: `${url}/static/totalUsers`,
  transactionHistoryData: `${url}/assetsTransaction/transactionHistoryForUserWithdrawDepositTransfer`,
  directAndIndirectGain: `${url}/assetsTransaction/transactionHistoryForUserActivationDirectGainANDActivationIndirectGain`,
  autoRenew: `${url}/user/autoRenew`,
  getPopularAssets: `${url}/static/getPopularAssets`,
  directReferralDashboard: `${url}/user/directReferralDashboard`,
  getRecentTransactions: `${url}/user/getRecentTransactions`,
  strategyProfit: `${url}/user/strategyProfit`,
  removeConnectedExchange: `${url}/exchangeWallet/removeConnectedExchange`,
  modifyPassword: `${url}/user/modifyPassword`,
  transactionPassword: `${url}/user/user-Forgot-Transaction-Password`,
  updateTransactionPassword: `${url}/user/forgotTransactionPasswordUpdate`,
  announcementContentList: `${url}/announcement/announcementContentList`,
  viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  addRemoveFavorites: `${url}/exchangeWallet/addRemoveFavorites`,
  conectexchangeList: `${url}/exchangeWallet/connectedExchangeList`,
  getSingleStaticContent: `${url}/static-content/getSingleStaticContentByType`,
  addFeedback: `${url}/contactUs/addFeedback`,
  feedbackListforWeb: `${url}/contactUs/feedbackListforWeb`,
  viewFeedback: `${url}/contactUs/viewFeedback`,
  // tradeHistory: `${url}/indicator/tradeHistory?limit=${10}`,
  activeTradingPairs: `${url}/indicator/activeTradingPairs`,

  //CMS Article
  listArticleByCategoryIdForUser: `${url}/admin/listArticleByCategoryIdForUser`,
  listCategoryForUser: `${url}/admin/listCategoryForUser`,
  viewArticleForUser: `${url}/admin/viewArticleForUser`,

  //AIStatergy
  // getCoinList: `${url}/indicator/getCoinList`,
  // exchangeList: `${url}/indicator/exchangeList`,
  // startAiSync: `${url}/indicator/startAiSync`,
  // stopAiSync: `${url}/indicator/stopAiSync`,
  // getAiSyncInfo: `${url}/indicator/getAiSyncInfo`,
  // getAiStrategyList: `${url}/indicator/app-getAiSyncHistory`,
  // getAiStrategyHistory: `${url}/indicator/getAiSyncHistory`,
  // coinMList: `${url}/indicator/coin-m`,
  // syncedCoinMList: `${url}/indicator/user-coin-list`,
  // syncSingleCoinM: `${url}/indicator/add-to-coinm`,

  // forex
  createAccount: `${url}/fx/create-account`,
  getAccountDetail: `${url}/fx/get-acount-details`,
  getOpenTrades: `${url}/fx/open-trades`,
  tradeHistory: `${url}/fx/trade-history`,
};
export default ApiConfig;
// update main
