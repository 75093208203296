import React, { useEffect, useMemo, useState } from 'react'
import MainContext from './MainContext'
import ApiConfig from '../ApiConfig';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { calculateTimeLeft } from '../Utils/Utils';

export default function ContextState(props) {
  const token = localStorage.getItem("token");

  const [justLogin, setJustLogin] = useState(false);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState({
    userPlan: false,
    fuelFee: false,
    fxData: false,
  });
  const [sidebar, setSidebar] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [endTime, setEndtime] = useState();
  const [exchange, setExchange] = useState("Binance");
  const [exchangeBalance, setExchangeBalance] = useState({});

  const [buySubscription, setBuySubscription] = useState(true);
  const [hasFuelFee, setHasFuelFee] = useState(false);
  const [level1Pass, setLevel1Pass] = useState(false);
  const [level2Pass, setLevel2Pass] = useState(false);
  const [currentStatusShow, setCurrentStatusShow] = useState(false);
  const [userFxData, setUserFxData] = useState([]);
  const [assetCount, setAccountAsset] = useState({});
  const [planAlert, setPlanAlert] = useState(false);
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const exchangeWallet = async () => {
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.exchangeWallet,
        headers: { token: token },
      });
      if (res.data.statusCode === 200) {
        setExchangeBalance(res?.data?.result);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (error.response?.data.responseMessage !== "jwt malformed") {
        if (error.response?.data.responseMessage) {
          toast.error(error.response?.data.responseMessage);
        } else {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  const getViewMyProfile = async (values) => {
    setLoading({ ...loading, userPlan: true });
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });
      setLoading({ ...loading, userPlan: false, });
      if (res.data.statusCode === 200) {
        setProfile(res?.data?.result);
        if (res.data?.result?.currentActivePlan?.validity !== 12 && justLogin) {
          setPlanAlert(true);
        }
        // setPlanExpiryDate(res?.data?.result?.planExpiryDate);
      } else {
        if (res.data.responseMessage !== "jwt malformed") {
          toast.error(res.data.responseMessage);
        }
      }
    } catch (error) {
      setLoading({ ...loading, userPlan: false });
      if (
        error.response?.data?.responseMessage === "User not found" ||
        error.response?.data?.responseMessage === "jwt malformed"
      ) {
        localStorage.clear();
      }
    }
  }

  const getFxStatus = async () => {
    try {
      setLoading({ ...loading, fxData: true });
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getAccountDetail,
        headers: { token: token },
      });
      setLoading({ ...loading, fxData: false });
      setUserFxData(res.data.result);
      if (res.data.result?.status?.toLowerCase() === "pending" || res.data.result?.status === "inactive") {
        setCurrentStatusShow(true);
      }
      if (res.data.result?.status?.toLowerCase() === "active") {
        setLevel2Pass(true);
        localStorage.setItem("level2pass", true);
      } else {
        localStorage.removeItem("level2pass");
        setLevel2Pass(false);
      }
    } catch (err) { }
  };

  const getAssetDetails = async (values) => {
    const token = localStorage.getItem("token");

    try {
      // setIsRotated(true);
      setLoading({ ...loading, fuelFee: true });
      const res = await Axios({
        method: "GET",
        url: ApiConfig.assetDetails,
        headers: { token: token },
        params: {
          exchangeName: exchange,
        },
      });

      if (res.data.statusCode === 200) {
        setAccountAsset(res.data.result);
        // setIsRotated(false);
        if (res.data.result?.fuelAccountBalance?.total > 0) {
          setHasFuelFee(true);
          if (
            res.data.result?.fuelAccountBalance?.total > 20 &&
            buySubscription &&
            profile?.currentActivePlan?.validity === 12
          ) {
            setLevel1Pass(true);
          }
        }
      } else {
        // setIsRotated(false);
      }
      setLoading({ ...loading, fuelFee: false });
    } catch (error) {
      // setIsRotated(false);
      setLoading({ ...loading, fuelFee: false });
    }
  };

  useEffect(() => {
    if (token && profile?.currentActivePlan?.status?.toLowerCase() === "active") {
      setBuySubscription(false);

      getAssetDetails();
    }
  }, [token, profile?.currentActivePlan?.status]);

  useEffect(() => {
    if (token && profile) {
      getAssetDetails();
    }
  }, [token, exchange, profile]);

  // useEffect(() => {
  //   let pass = localStorage.getItem("level2pass");
  //   if (token && pass) {
  //     setBuySubscription(false);
  //     setHasFuelFee(true);
  //     setLevel1Pass(true);
  //     setLevel2Pass(true);
  //   }
  // }, [token]);

  useMemo(() => {
    if (token) {
      getViewMyProfile();
      exchangeWallet();
      // connectedExchangeList();
      getFxStatus();
    }
  }, [token]);

  const value = {
    profile,
    loading, setLoading,
    justLogin, setJustLogin,
    sidebar, setSidebar,
    timeLeft, setTimeLeft,
    endTime, setEndtime,
    exchange, setExchange,
    exchangeBalance,
    buySubscription, setBuySubscription,
    hasFuelFee, setHasFuelFee,
    level1Pass, setLevel1Pass,
    level2Pass, setLevel2Pass,
    currentStatusShow, setCurrentStatusShow,
    userFxData, setUserFxData,
    assetCount, setAccountAsset,
    planAlert, setPlanAlert,
    purchaseOpen, setPurchaseOpen,
    // getViewMyProfile: () => getViewMyProfile(),
    exchangeWallet: () => exchangeWallet(),
    getFxStatus: () => getFxStatus(),
    getAssetDetails: (values) => getAssetDetails(values),
  }


  return (
    <MainContext.Provider value={value}>
      {props.children}
    </MainContext.Provider>
  )
}