import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

const ConfirmationModal = ({ handleClose, title, desc, confirmationHandler, loading }) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <div>
        <Modal.Body>
          <p>{desc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type='button' onClick={handleClose}>No</Button>
          <Button variant='primary' type='submit' className='modalSubmit' onClick={confirmationHandler} disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Yes"}
          </Button>
        </Modal.Footer>
      </div>
    </>
  )
}

export default ConfirmationModal