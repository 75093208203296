import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import OwlCarousel from 'react-owl-carousel';

const Home = () => {

  const featureList = [
    {
      id: 1,
      img: '/images/user/affiliate.png',
      title: 'Dynamic Affiliate Structure',
      description: 'Control and customize your multi-level affiliate network.',
    },
    {
      id: 2,
      img: '/images/user/vpn.png',
      title: 'VPS-less Copier',
      description: 'Enjoy seamless trading without the need for a VPS.',
    },
    {
      id: 3,
      img: '/images/user/adminPanel.png',
      title: 'Personalized Admin Panel',
      description: 'Manage your trading and affiliates with ease.',
    },
    {
      id: 4,
      img: '/images/user/userPanel.png',
      title: 'Custom User Panel',
      description: 'A tailored dashboard for a better trading experience.',
    },
  ];

  const featureOptions = {
    0: {
      items: 1,
    },
    575: {
      items: 2,
    },
    991: {
      items: 3,
    },
    1199: {
      items: 3.5,
    },
    1599: {
      items: 4,
    },
  };

  return (
    <>
      <div className='bannerSec'>
        <Container>
          <Row className='gy-4'>
            <Col lg={6} className='bannerTxt'>
              <h2>Revolutionize Your <span>FOREX TRADING</span> Experience</h2>
              <p className='pe-xxl-5 me-xxl-5 my-4'>Copy trading made easy with instant execution and dynamic affiliate control.</p>
              <div className='d-flex gap-2'>
                <Button className='themeBtn'>Register</Button>
                <Button className='themeBtn active'>Learn More</Button>
              </div>
            </Col>
            <Col lg={6}>
              <div className='bannerIng'>
                <img src='/images/user/bannerImg.png' alt='banner' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='featureSec'>
        <h2 className='mainTitle mb-4'>Features</h2>
        <div className='featureSlider'>
          <Container>
            <OwlCarousel className='featureCarousel owl-theme' loop autoplay autoplaySpeed={3000} responsive={featureOptions} margin={20} dots={false}>
              {featureList?.map((item, index) => {
                return (
                  <div className='item featureDiv'>
                    <img src={item?.img} alt='' className='mb-3' />
                    <h4>{item?.title}</h4>
                    <p>{item?.description}</p>
                  </div>
                )
              })}
            </OwlCarousel>
          </Container>
        </div>
      </div>

      <div className='workingSec'>
        <Container>
          <h2 className='mainTitle mb-0 mb-lg-4'>How It Works</h2>
          <Row className='workingRow'>
            <Col sm={6} lg={3}>
              <div className='workingDiv'>
                <div>
                  <img src='/images/user/step1.png' alt='' className='mb-3' />
                  <h4>Registration</h4>
                  <p className='stepNum'>01</p>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3}>
              <div className='workingDiv'>
                <div>
                  <img src='/images/user/step2.png' alt='' className='mb-3' />
                  <h4>Fill Your Details</h4>
                  <p className='stepNum'>02</p>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3}>
              <div className='workingDiv'>
                <div>
                  <img src='/images/user/step3.png' alt='' className='mb-3' />
                  <h4>Pay For Subscription</h4>
                  <p className='stepNum'>03</p>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3}>
              <div className='workingDiv'>
                <div>
                  <img src='/images/user/step4.png' alt='' className='mb-3' />
                  <h4>Get Personalized Admin Panel</h4>
                  <p className='stepNum'>04</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='affiliateSec'>
        <div className='affiliateBg py-5'>
          <Container>
            <Row>
              <Col xl={10} xxl={8} className='mx-auto'>
                <h2 className='mainTitle'>User Centric Affiliate Program</h2>
                <p>Become an admin and take control of your trading network. Personalize your affiliate structure and manage your team with ease.</p>

                <h5 className='text-black fw-bold mt-4 mb-2'>Benefits</h5>
                <ul>
                  <li>Customizable multi-level structure</li>
                  <li>personal admin</li>
                  <li>personal user dashboards</li>
                  <li>high commissions</li>
                </ul>

                <Button className='themeBtn active mt-4'>Become An Admin</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className='pricingSec'>
        <Container>
          {/* <Row className='justify-content-center'>
            <Col xs={11} sm={11}> */}
          <h2 className='mainTitle mb-4'>Our Pricing</h2>
          <div className='pricingDiv'>
            <h3>One Peak</h3>

            <h2 className='mt-4'>$ 100</h2>
            <p className='mb-5'>01 YEAR</p>

            <Button className='themeBtn'>Subscribe Now</Button>
          </div>
          {/* </Col>
          </Row> */}
        </Container>
      </div>

    </>
  )
}

export default Home