export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}

export function sortAccount(add) {
  const sortAdd = `${add?.slice(0, 0)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const fixedDecimal = (value, precession) => {
  return parseFloat(value) % 1 !== 0
    ? parseFloat(value)?.toFixed(precession)
    : parseFloat(value);
};
