import React, { useState, useContext } from "react";
import './network.css';
import { Button } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import PredictionProfit from "./PredictionProfit";
import ReferralSummary from "./ReferralSummary";
import ReferFriends from "./ReferFriends";
import DirectReferral from "./DirectRefferal";
import ReferTeam from "./ReferralTeam";

const Network = () => {
  const user = useContext(MainContext);
  // const [Eyebutton, setEyebutton] = useState(true);
  // const [showInfo, setShowInfo] = useState(false);
  // const [badgeLevel, setBadgeLevel] = useState(1);
  const [selectedTable, setSelectedTable] = useState('team');

  const [teamSize, setTeamSize] = useState({
    team: 0,
    percentforty: 0,
    percentsixty: 0,
  });
  // const [currentUserLevel, setcurrentUserLevel] = useState(0);
  function getTeamSize(teamsize, percentforty, percentsixty, team60Head) {
    setTeamSize({
      team: teamsize,
      percentforty: percentforty,
      percentsixty: percentsixty,
      team60Head: team60Head,
    });
  }

  return (
    <>
      <div>
        <div className="teamBtns d-flex flex-wrap gap-3 mb-3">
          <Button className={selectedTable === "team" && 'active'} onClick={() => { setSelectedTable("team"); }}>My Team</Button>
          <Button className={selectedTable === "teamCommission" && 'active'} onClick={() => { setSelectedTable("teamCommission"); }}>My Team Commission</Button>
        </div>

        {selectedTable === "team" &&
          <>
            <ReferFriends code={user?.profile?.referralCode} />
            <DirectReferral getTeamSize={getTeamSize} />
            <ReferTeam />
          </>
        }

        {selectedTable === "teamCommission" &&
          <>
            <PredictionProfit />
            <ReferralSummary />
          </>
        }

        {/* <div>
          <div>
            <p>
              {user?.profile?.nickName
                ? user?.profile?.nickName.substring(0, 13)
                : "User"}
            </p>
            {user?.profile?.email?.length > 38 ?
              <div>
                <p>ID:</p>
                <p>
                  {!Eyebutton
                    ? user?.profile?.email &&
                    user?.profile?.email.substring(0, 40)
                    : user?.profile?.email
                      .split("")
                      .map(() => "*")
                      .join("")
                      .substring(0, 40)}
                  {Eyebutton ? (
                    <FaEyeSlash />
                  ) : (
                    <FaEye />
                  )}
                </p>
              </div>
              :
              <p>
                <span>ID:</span>
                {!Eyebutton
                  ? user?.profile?.email &&
                  user?.profile?.email.substring(0, 40)
                  : user?.profile?.email
                    ?.split("")
                    ?.map(() => "*")
                    ?.join("")
                    ?.substring(0, 40)}
                {Eyebutton ? (
                  <FaEyeSlash />
                ) : (
                  <FaEye />
                )}
              </p>
            }
          </div>
          <div>
            {user?.profile?.planSubscription ? (
              <>
                {badgeLevel < 10 ? (
                  <div>
                    <div>
                      <img src={`https://res.cloudinary.com/dovl2qxdj/image/upload/v1697811571/level${badgeLevel}.png`} alt={`badge-level-${badgeLevel}`} />
                      <p>
                        Level {currentUserLevel} <AiOutlineInfoCircle onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} size="20px" />
                      </p>
                      {showInfo ?
                        <div>
                          <p>Current Direct Referrals : {user?.profile?.activeDirectReferral || 0}</p>
                          <p>Required Referrals for level {currentUserLevel + 1} : {imageConditions[currentUserLevel]?.condition?.activatedDirect || 0}</p>
                          <p>Current Active Package : {user?.profile?.currentActivePlan?.validity} Months</p>
                          {imageConditions[currentUserLevel]?.condition?.activatedPackageMonth && (
                            <p>Required Active Package : {imageConditions[currentUserLevel]?.condition?.activatedPackageMonth} Months</p>
                          )}
                          {teamSize?.team ? <p>Current Team Size : {teamSize?.team}</p> : ""}
                        </div>
                        : ""}
                    </div>
                    <div>
                      {currentUserLevel < 10 && (
                        <>
                          <img src={`https://res.cloudinary.com/dovl2qxdj/image/upload/v1697811571/level${currentUserLevel + 1}.png`} alt={`badge-level-${currentUserLevel + 1}`} />
                          <p>Level {currentUserLevel + 1}</p>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <div>
                        <p>
                          Congratulations! 🎉
                        </p>
                        <div className="d-flex">
                          You have achieved the highest level <AiOutlineInfoCircle onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} size="20px" />
                        </div>
                      </div>
                      {showInfo ?
                        <div>
                          <p>
                            Current Direct Referrals :
                            {user?.profile?.activeDirectReferral || 0}
                          </p>

                          <p>
                            Required Referrals for level
                            {currentUserLevel + 1} :
                            {imageConditions[currentUserLevel]
                              ?.condition?.activatedDirect || 0}
                          </p>
                          <br />
                          <p>
                            Current Active Package :
                            {
                              user?.profile?.currentActivePlan
                                ?.validity
                            }
                            Months
                          </p>
                          <br />
                          {imageConditions[currentUserLevel]
                            ?.condition
                            ?.activatedPackageMonth && (
                              <>

                                <p>
                                  Required Active Package :
                                  {
                                    imageConditions[
                                      currentUserLevel
                                    ]?.condition
                                      ?.activatedPackageMonth
                                  }
                                  Months
                                </p>
                                <br />
                              </>
                            )}
                          {teamSize?.team ? (
                            <>
                              <p>
                                Current Team Size :
                                {teamSize?.team}
                              </p>

                              <br />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        : ""
                      }

                      <img src={`https://res.cloudinary.com/dovl2qxdj/image/upload/v1697811571/level10.png`} alt={`badge-level-10`} />
                    </div>
                  </>
                )}
              </>
            ) :
              <p>
                <Link to="/pricing">Upgrade you plan to get referral bonus</Link>
              </p>}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Network;
