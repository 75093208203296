import React, { useState } from "react";

// import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, } from "@material-ui/core";
// import CircularProgress from "@mui/material/CircularProgress";
// import { FaSortAmountDown } from "react-icons/fa";
import moment from "moment";
import { Button, Spinner, Table } from "react-bootstrap";
import { LuRefreshCw } from "react-icons/lu";

export default function OpenTradeTable(props) {
  const { headData, titleText, bodyData, sortOrder, handleSort, } = props;
  return (
    <div>
      {props?.loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {/* <div className="d-flex align-items-center justify-content-between mb-4">
            <h5>{titleText}</h5>
            <div>
              {!props.openData && (
                <Button className='refreshBtn' onClick={() => props.getOpenTrades()}><LuRefreshCw color='#3DACF4' fontSize={'22px'} /> Refresh</Button>
              )}
            </div>
          </div> */}

          {bodyData?.length !== 0 ? (
            <Table responsive className="forexTable">
              <thead style={{ position: "sticky", top: "0" }}>
                <CustomTableHead
                  headData={headData}
                  handleSort={handleSort}
                  sortOrder={sortOrder}
                />
              </thead>

              <tbody>
                {bodyData?.map((data) => (
                  <CustomTableRow values={data} />
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center">
              <p>No data found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
function CustomTableHead({ headData, handleSort, sortOrder }) {
  return (
    <tr>
      <>
        {headData?.map((data) => (
          <th>
            {data}
            {data === "Unrealised PNL" ?
              <span style={{ color: sortOrder === "asc" ? "#26A17B" : "#D33534", cursor: "pointer", fontSize: "1.8em", }} onClick={handleSort}>{sortOrder === "asc" ? "↑" : "↓"}</span>
              : ""
            }
          </th>
        ))}
      </>
    </tr>
  );
}

function CustomTableRow({ values }) {
  return (
    <tr>
      <>
        <td style={{ background: values?.type === 0 ? "#00C64F" : "#FF4026", cursor: "pointer", maxWidth: '5px', width: '5px' }}></td>
        <td style={{ minWidth: "60px" }}>{values.symbol}</td>
        <td style={{ minWidth: "60px" }}>{values.volume}</td>
        <td style={{ minWidth: "60px" }}>{values.price}</td>
        <td style={{ minWidth: "60px" }}>{values?.Market_Price}</td>
        <td style={{ minWidth: "60px", color: values?.PNL > 0 ? "#00C64F" : "#FF4026", }}>{values.PNL}</td>
        <td style={{ minWidth: "60px" }}>{moment(values?.date).format("DD-MM-YY || hh:mm:ss")}</td>
      </>
    </tr >
  );
}
