import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment/moment";
import "./auth.css";
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import ApiConfig from "../../Configs/ApiConfig";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ROUTES from "../../Configs/Routes";
import axios from "axios";
import { Helmet } from "react-helmet";

function Signup() {
  const token = localStorage.getItem('token');
  const context = useContext(MainContext);
  const navigate = useNavigate();
  const location = useLocation();

  // for resetting the otp timer
  useEffect(() => {
    context?.setEndtime(moment().add(0, "m").unix());
  }, []);

  const [isloading, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    country: "",
    nickName: "",
    email: "",
    password: "",
    confirmPwd: "",
    check: false,
  });
  const [errors, setErrors] = useState({
    country: "",
    nickName: "",
    email: "",
    password: "",
    confirmPwd: "",
    check: "",
  });

  // const [deviceBrower, setDeviceBowser] = useState("");
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);

  // getting invite code query string
  const inviteCode = location.search ? location.search.substring(12) : "";
  const [invite, setInvite] = useState(inviteCode);

  // const getData = async () => {
  //   const res = await Axios.get("https://geolocation-db.com/json/");
  //   setIP(res.data);
  // };
  // useEffect(() => {
  //   getData();
  // }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if ((formData.email === "") && (formData.password === "") && (formData.country === "") && (formData.nickName === "") && (formData.confirmPwd === "") && (!formData.check)) {
      setErrors({
        ...errors,
        email: "Email is required.",
        password: "Password is required.",
        country: "Please seelct your country.",
        confirmPwd: "Confirm password is required",
        nickName: "Nickname is required",
        check: "Please accept our terms and conditions, privacy policy and user agreement."
      });
      return;
    } else if (formData.country === "") {
      setErrors({ ...errors, country: "Please seelct your country." });
      return;
    } else if (formData.nickName === "") {
      setErrors({ ...errors, nickName: "Nickname is required" });
      return;
    } else if (!formData.nickName.toString().match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/)) {
      setErrors({ ...errors, nickName: "Only alphabets and whitespaces are allowed for Nickname." });
      return;
    } else if (formData.email === "") {
      setErrors({ ...errors, email: "Email is required." });
      return;
    } else if (formData.password === "") {
      setErrors({ ...errors, password: "Password is required." });
      return;
    } else if (formData.password.toString().length < 6 || formData.password.toString().length > 20) {
      setErrors({ ...errors, password: "Password must contain 6 to 20 character" });
      return;
    } else if (!formData.password.toString().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/)) {
      setErrors({ ...errors, password: "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character" });
      return;
    } else if (formData.confirmPwd === "") {
      setErrors({ ...errors, confirmPwd: "Confirm password is required." });
      return;
    } else if (formData.password !== formData.confirmPwd) {
      setErrors({ ...errors, confirmPwd: "Passwords do not match." });
      return;
    } else if (!formData.check) {
      setErrors({ ...errors, check: "Please accept our terms and conditions, privacy policy and user agreement." });
      return;
    } else {
      setErrors({
        ...errors,
        country: "",
        nickName: "",
        email: "",
        password: "",
        confirmPwd: "",
      })
    }

    setLoader(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.userRegister,

        data: {
          country: formData.country,
          email: formData.email,
          password: formData.password,
          confirmPassword: formData.confirmPwd,
          invitationCode: invite ? invite : "Admin123",
          nickName: formData.nickName,
        },
      });

      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);
        setLoader(false);
        localStorage.setItem("email", formData.email);
        context?.setEndtime(moment().add(2, "m").unix());
        navigate(ROUTES.SIGNUP_VERIFY);
      } else {
        toast.warn(res.data.responseMessage);
        setLoader(false);
      }
    } catch (error) {
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
        setLoader(false);
      } else {
        toast.error("Something went wrong. Please try again.");
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    axios.get("/static/json/countries.json").then(function (response) {
      setCountries(response.data.countries);
    });
    // const { detect } = require("detect-browser");
    // const browser = detect();
    // if (browser) {
    //   setDeviceBowser(browser?.name);
    // }
    // handleSearchChange()
  }, []);

  // const handleSearchChange = (searchString) => {
  //   const filtered = countries.filter(
  //     (country) =>
  //       country.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
  //   );
  //   setFilteredCountries(filtered);
  // };

  useEffect(() => {
    if (token) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [token])

  return (
    <>
      <Helmet>
        <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script>
      </Helmet>
      <div className="authDiv">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="bannerTxt my-lg-4 pe-lg-5 me-xxl-5">
                <h2>Welcome to <span>FOREX AI TRADE</span> Family!</h2>
                <p className="pe-xxl-5 me-xxl-5">A Workspace to Over 1 Million Happy User's Worldwide.</p>
              </div>
              <div className="authImg">
                <img src='/images/user/authImg.png' alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="loginDiv">
                <Form onSubmit={handleFormSubmit}>
                  <h2 className="authTitle mb-3">Registration</h2>

                  <Row className='gx-2 gy-3'>
                    <Col sm={8}>
                      {/* <label>NickName <span style={{ color: "red" }}>*</span></label> */}
                      <Form.Control placeholder="Enter Your Name" type="text" minLength={4} maxLength={30} value={formData.nickName} onChange={(e) => { setFormData({ ...formData, nickName: e.target.value }) }} />
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.nickName || ''}</p>
                    </Col>

                    <Col sm={4}>
                      {/* <label>Select Country <span style={{ color: "red" }}>*</span></label> */}
                      <Form.Select
                        value={formData.country}
                        onChange={(e) => {
                          setFormData({ ...formData, country: e.target.value })
                          // handleSearchChange(e.target.value);
                        }}
                      >
                        <option value="">Select Country</option>
                        {countries?.length > 0 && countries?.map((country, index) => {
                          return (
                            <option value={country.name} key={index}>{country.name}</option>
                          )
                        })}
                      </Form.Select>
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.country || ''}</p>
                    </Col>

                    <Col sm={12}>
                      {/* <label>Email Address <span style={{ color: "red" }}>*</span></label> */}
                      <Form.Control placeholder="Please Enter An Email Address" type="email" maxLength={256} value={formData.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} />
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.email || ''}</p>
                    </Col>

                    <Col sm={12}>
                      {/* <label>Login Password <span style={{ color: "red" }}>*</span></label> */}
                      <InputGroup>
                        <Form.Control type={showPassword ? "text" : "password"} placeholder="Please Enter your password" onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }} value={formData.password} />
                        <InputGroup.Text>
                          <div onClick={() => setShowPassword(!showPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </InputGroup.Text>
                      </InputGroup>
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.password || ''}</p>
                    </Col>

                    <Col sm={12}>
                      {/* <label>Confirm Password <span style={{ color: "red" }}>*</span></label> */}
                      <InputGroup>
                        <Form.Control type={showConfirmPassword ? "text" : "password"} placeholder="Confirm Password" onChange={(e) => { setFormData({ ...formData, confirmPwd: e.target.value }) }} value={formData.confirmPwd} minLength={8} maxLength={20} />
                        <InputGroup.Text>
                          <div onClick={() => setshowConfirmPassword(!showConfirmPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </InputGroup.Text>
                      </InputGroup>
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.confirmPwd || ''}</p>
                    </Col>

                    <Col sm={12}>
                      {/* <label>Invitation code <span style={{ color: "grey" }}>(Optional)</span></label> */}
                      <Form.Control
                        placeholder="Invitation code"
                        type="text"
                        maxLength={6}
                        value={invite}
                        onChange={(e) => setInvite(e.target.value)}
                      />
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.invitecode || ''}</p>
                    </Col>

                    <Col sm={12}>
                      <Form.Check type="checkbox" name="checkedC" checked={formData.check} onChange={(e) => setFormData({ ...formData, check: e.target.checked })}
                        label={<>
                          I accept the{" "}
                          <span style={{ color: '#3DACF4' }} onClick={() => window.open(ROUTES.TERMS, "_blank")}>Terms and Conditions,{" "}</span>
                          <span style={{ color: '#3DACF4' }} onClick={() => window.open(ROUTES.PRIVACY, "_blank")}>Privacy Policy{" "}</span>
                          and{" "}
                          <span style={{ color: '#3DACF4' }} onClick={() => window.open(ROUTES.SERVICE, "_blank")}>User service agreement.</span> Of Olympus
                        </>}
                      />
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.check || ''}</p>
                    </Col>

                    <Col sm={12}>
                      <Button type="submit" className="loginBtn" disabled={isloading}>
                        Register {isloading && <Spinner size="sm" />}
                      </Button>

                      <p className="text-center mt-2">Already have an Account? <Link to={ROUTES.LOGIN} style={{ color: "#3DACF4" }}>Login</Link></p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Signup;
