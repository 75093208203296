import React, { useContext, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import './forex.css';
import CustomTableComponentForex from "./tableComponent";
import ApiConfig from "../../Configs/ApiConfig";
import Axios from "axios";
import { MdPendingActions } from "react-icons/md";
import SubmissionForm from "./submissionForm";
import OpenTradeTable from "./openComponent";
import MainContext from "../../Configs/Context/MainContext";
import AccountOptions from "../Dashboard/AccountOptions";
import FxAccountTable from "./FxAccountTable";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import ROUTES from "../../Configs/Routes";
import { useNavigate } from "react-router-dom";
import ConnectAccount from "../Dashboard/ConnectAccount";
import ForexCounts from "./ForexCounts";
import { LuRefreshCw } from "react-icons/lu";

const headDataOpen = ["Currency", "Lot Size", "Entry Price", "Mark Price", "Date", "Unrealised PNL",];
const headDataRecent = ["Profit", "Currency", "Side", "Fuel Fee", "Time"];

export default function ForexTrade() {
  const token = localStorage.getItem("token");
  const context = useContext(MainContext);
  const [showForm, setShowForm] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState({
    formSubmit: false,
    historyData: false,
  });
  const [openLoad, setOpenLoad] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    contactEmail: "",
    broker: "",
    accountType: "",
    serverUrl: "",
    accountNumber: "",
    fxPass: "",
    fxUsername: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    contactEmail: "",
    broker: "",
    accountType: "",
    serverUrl: "",
    accountNumber: "",
    fxPass: "",
  });

  const [selectedTable, setSeletedTable] = useState("open");
  const [recentData, setRecentData] = useState([]);
  const [openData, setOpenData] = useState([]);

  useEffect(() => {
    if (context?.userFxData?.name) {
      setFormData({
        name: context?.userFxData?.name,
        mobile: context?.userFxData?.mobile,
        contactEmail: context?.userFxData?.contactEmail,
        broker: context?.userFxData?.broker,
        accountType: context?.userFxData?.accountType,
        serverUrl: context?.userFxData?.serverUrl,
        accountNumber: context?.userFxData?.accountNumber,
        fxPass: context?.userFxData?.fxPass,
        fxUsername: context?.userFxData?.fxUsername,
      });
    }
  }, [context?.userFxData])

  const postForm = async () => {
    if (!validate()) {
      return;
    }
    try {
      setLoading({ ...loading, formSubmit: true });
      const res = await Axios({
        method: "POST",
        url: ApiConfig.createAccount,
        headers: { token: token },
        data: formData,
      });
      context?.setUserFxData(res.data.result);
      context?.setCurrentStatusShow(true);
      setLoading({ ...loading, formSubmit: false });
    } catch (error) {
      setLoading({ ...loading, formSubmit: false });
    }
  };

  // useEffect(() => {
  //   let pass = localStorage.getItem("level2pass");
  //   if (token && pass) {
  //     getOpenTrades();
  //     getTradeHistory();
  //   }
  // }, [token]);

  const validate = () => {
    if (
      formData.name === "" &&
      formData.mobile === "" &&
      formData.contactEmail === "" &&
      formData.broker === "" &&
      formData.accountType === "" &&
      formData.serverUrl === "" &&
      formData.accountNumber === "" &&
      formData.fxPass === ""
    ) {
      setErrors({
        ...errors,
        name: "Name is required",
        mobile: "Mobile is required",
        contactEmail: "Contact Email is required",
        broker: "Broker is required",
        accountType: "Account Type is required",
        serverUrl: "Server Url is required",
        accountNumber: "Account Number is required",
        fxPass: "Forex Password is required"
      });
      return false;
    }
    if (formData.name === "") {
      setErrors({ ...errors, name: "Name is required" });
      return false;
    }
    if (formData.mobile === "") {
      setErrors({ ...errors, mobile: "Mobile is required" });
      return false;
    }
    if (formData.contactEmail === "") {
      setErrors({ ...errors, contactEmail: "Contact Email is required" });
      return false;
    }
    if (formData.broker === "") {
      setErrors({ ...errors, broker: "Broker is required" });
      return false;
    }
    if (formData.accountType === "") {
      setErrors({ ...errors, accountType: "Account Type is required" });
      return false;
    }
    if (formData.serverUrl === "") {
      setErrors({ ...errors, serverUrl: "Server Url is required" });
      return false;
    }
    if (formData.accountNumber === "") {
      setErrors({ ...errors, accountNumber: "Account Number is required" });
      return false;
    }
    if (formData.fxPass === "") {
      setErrors({ ...errors, fxPass: "Forex Password is required" });
      return false;
    }
    setErrors({
      name: "",
      mobile: "",
      contactEmail: "",
      broker: "",
      serverUrl: "",
      accountNumber: "",
      fxPass: "",
      fxUsername: "",
    });
    return true;
  };

  // const getOpenTrades = async () => {
  //   try {
  //     setOpenLoad(true)
  //     const res = await Axios({
  //       method: "GET",
  //       url: ApiConfig.getOpenTrades,
  //       headers: { token: token },
  //       params: {
  //         sortType: sortOrder,
  //         sortKey: "PNL",
  //       },
  //     });
  //     setOpenLoad(false)
  //     setOpenData(res.data?.result[0]?.data[0]?.openOrdersArray);
  //   } catch (err) {
  //     setOpenLoad(false)
  //   }
  // };
  // const getTradeHistory = async () => {
  //   try {
  //     setLoading({ ...loading, historyData: true });
  //     const res = await Axios({
  //       method: "GET",
  //       url: ApiConfig.tradeHistory,
  //       headers: { token: token },
  //     });
  //     setLoading({ ...loading, historyData: false });
  //     setRecentData(res.data.result[0].data);
  //   } catch (err) {
  //     setLoading({ ...loading, historyData: false });
  //   }
  // };

  // useEffect(() => {
  //   if (token && context?.level2Pass) {
  //     getOpenTrades();
  //     getTradeHistory();
  //   }
  // }, [token, context?.level2Pass, sortOrder]);

  // const handleSort = () => {
  //   setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  // };

  const iconRotate = () => {
    // getTransactionHistory();

    // setIsRotated(!isRotated);
    // user?.exchangeWallet()
    //   .then(() => {
    //     setIsRotated(false);
    //   });
  };

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3 gap-3">
        <h5>My Account</h5>
        {context?.userFxData?.status && !showForm &&
          <Button className="backBtn ms-auto" onClick={() => { setShowForm(!showForm); }}>Edit Account</Button>
        }
        <Button className='refreshBtn ms-0' onClick={() => iconRotate()} disabled={isRotated}><LuRefreshCw color='#3DACF4' fontSize={'22px'} /> Refresh</Button>
      </div>
      <ForexCounts />
      {
        !context?.level1Pass || !context?.hasFuelFee ? (
          <ConnectAccount />
        ) : (context?.loading?.fxData ?
          <div className="text-center mb-4">
            <Spinner />
          </div>
          :
          <>
            {/* {!context?.level2Pass ? ( */}
            <>
              {context?.userFxData?.status ? (
                <div className="fxPendingDiv mb-5">
                  {/* <MdPendingActions size={100} color={"#3DACF4"} />
                    <p>Your details are submitted succesfully.</p>
                    <h4>Current Status : {context?.userFxData?.status?.toUpperCase()}</h4>
                    <Button className="showFormBtn mb-3" onClick={() => { setShowForm(!showForm); }}>
                      {showForm ? "Cancel" : "View / Edit"}
                    </Button> */}

                  {showForm ?
                    <SubmissionForm
                      formData={formData}
                      setFormData={setFormData}
                      errors={errors}
                      postForm={postForm}
                      loading={loading}
                      showForm={showForm}
                      setShowForm={setShowForm}
                    />
                    :
                    <FxAccountTable />
                  }
                </div>
              ) : (
                <div>
                  {!context?.currentStatusShow ? (
                    <div className="text-center mb-4">
                      <h2>Please Enter Your MT 5 Account Detail's</h2>
                      <p style={{ color: 'red' }}>*Note: Only MT 5 Account Acceptable</p>
                    </div>
                  ) : (
                    <div className="text-center mb-4">
                      <p>
                        Current Status: {context?.userFxData?.status?.toUpperCase()}
                        {context?.userFxData?.remark ? <p>Reason : {context?.userFxData?.remark?.toUpperCase()}</p> : ""}
                      </p>
                      <p style={{ color: 'red', fontSize: '12px' }}>
                        {context?.userFxData?.status === "inactive" &&
                          "Please Fill correct details again and submit the form if you want to activate your account"
                        }
                      </p>
                    </div>
                  )}
                  <SubmissionForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    postForm={postForm}
                    loading={loading}
                  />
                </div>
              )}
            </>
            {/* ) : ( */}
            <div>
              {/* <FxAccountTable /> */}

              {/* <div>
                  <p>Exchange Balance : $ {context?.userFxData?.accountBalance}</p>
                  <p>Total Deposit : $--</p>
                  <p>Total Withdrawal : $--</p>
                </div>
                <div>
                  <Button className={selectedTable === "open" && 'activeTableButton'} onClick={() => { setSeletedTable("open"); }}>Open Trade's</Button>
                  <Button className={selectedTable === "recent" && 'activeTableButton'} onClick={() => { setSeletedTable("recent"); }}>Recent Trade's</Button>
                </div>
                <div>
                  {selectedTable === "open" ? (
                    <OpenTradeTable
                      headData={headDataOpen}
                      bodyData={openData}
                      titleText={"Open Positions"}
                      loading={openLoad}
                      getOpenTrades={getOpenTrades}
                      handleSort={handleSort}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                    />
                  ) : (
                    <CustomTableComponentForex
                      headData={headDataRecent}
                      bodyData={recentData}
                      titleText={"Recent Trade"}
                      loading={loading.historyData}
                      getTradeHistory={getTradeHistory}
                    />
                  )}
                </div> */}
            </div>
            {/* )} */}
          </>
        )
      }

      <AccountOptions />
    </div >
  );
}
