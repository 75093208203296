import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DashboardChart = () => {
  const data = {
    labels: ['21-06', '22-06', '23-06', '24-06', '25-06', '26-06', '27-06', '28-06', '29-06', '30-06'],
    datasets: [
      {
        label: 'Dataset',
        data: [5, 10, 20, 40, 55, 60, 65, 59, 65, 81],
        fill: false,
        borderColor: '#3DACF4',
        tension: 0.1,
        pointBackgroundColor: '#3DACF4',
        pointBorderColor: '#3DACF4',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'User Returns'
      },
    },
    // layout: {
    //   padding: {
    //     top: 20,
    //     bottom: 30,
    //   }
    // },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: '#838383',
        },
        grid: {
          color: 'transparent',
        },
        border: {
          color: '#DFDFDF',
        },
      },
      y: {
        beginAtZero: true,
        // display: false, // Hide the y-axis
      },
    },
  };

  return <Line data={data} options={options} />;
}

export default DashboardChart