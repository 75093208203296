import React from 'react'

const AccountOptions = () => {
  return (
    <>
      <h3 className='text-center mb-4 mt-5'>If you haven't set up a trading account yet, feel free to select from the options provided below.</h3>

      <div className='accountOptions'>
        <a href="https://hmarkets.com/live-account-pre-registration/?refid=10612&cmp=4y6g6a5c&ent=hm" target="_blank" rel="noreferrer">
          <img src="https://res.cloudinary.com/dcdeosynu/image/upload/v1711625789/hantecLogo.png" alt="" />
        </a>
        <a href="https://my.orontrade.com/Account/Register?id=mHdE4wWUgj" target="_blank" rel="noreferrer">
          <img src="https://res.cloudinary.com/dcdeosynu/image/upload/v1711625762/oronLogo.png" alt="" />
        </a>
        <a href="https://one.exnesstrack.net/a/606lj7jbfl" target="_blank" rel="noreferrer">
          <img src="https://res.cloudinary.com/dcdeosynu/image/upload/v1711625745/exnessLogo.png" alt="" />
        </a>
      </div>
    </>
  )
}

export default AccountOptions