import React from "react";
import { Button, Form } from "react-bootstrap";

export default function SubmissionForm({ formData, setFormData, errors, postForm, loading, showForm, setShowForm, }) {
  return (
    <>
      <div className="fxAccountForm">
        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Name :</Form.Label>
          <div>
            <Form.Control placeholder="Your Name" type="text" value={formData?.name} onChange={(e) => { setFormData({ ...formData, name: e.target.value, fxUsername: e.target.value, }); }} />
            {errors?.name && <span style={{ color: "red" }}>{errors.name}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Mobile Number :</Form.Label>
          <div>
            <Form.Control placeholder="+91 9834343434" type="number" value={formData?.mobile} onChange={(e) => setFormData({ ...formData, mobile: e.target.value })} />
            {errors?.mobile && <span style={{ color: "red" }}>{errors.mobile}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Contact Email :</Form.Label>
          <div>
            <Form.Control placeholder="xyz@gmai.com" type="email" value={formData?.contactEmail} onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value, })} />
            {errors?.contactEmail && <span style={{ color: "red" }}>{errors.contactEmail}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Broker :</Form.Label>
          <div>
            <Form.Control placeholder="HANITECH" type="text" value={formData?.broker} onChange={(e) => setFormData({ ...formData, broker: e.target.value })} />
            {errors?.broker && <span style={{ color: "red" }}>{errors.broker}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Account Type :</Form.Label>
          <div>
            <Form.Select value={formData?.accountType} onChange={(e) => { setFormData({ ...formData, accountType: e.target.value }) }}>
              <option value=''>Select Account Type</option>
              <option value='MT4'>MT4</option>
              <option value='MT5'>MT5</option>
            </Form.Select>
            {errors?.accountType && <span style={{ color: "red" }}>{errors.accountType}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Server Name :</Form.Label>
          <div>
            <Form.Control placeholder="ADFD09034SD" type="text" value={formData?.serverUrl} onChange={(e) => setFormData({ ...formData, serverUrl: e.target.value, })} />
            {errors?.serverUrl && <span style={{ color: "red" }}>{errors.serverUrl}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Forex Account No. :</Form.Label>
          <div>
            <Form.Control placeholder="054878434" type="number" value={formData?.accountNumber} onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value, })} />
            {errors?.accountNumber && <span style={{ color: "red" }}>{errors.accountNumber}</span>}
          </div>
        </Form.Group>

        <Form.Group className="fxAccInput mb-3">
          <Form.Label>Forex Password : <p style={{ fontSize: '12px' }}>(Trading Password)</p></Form.Label>
          <div>
            <Form.Control placeholder="ADFD09034SD" type="text" value={formData?.fxPass} onChange={(e) => setFormData({ ...formData, fxPass: e.target.value, })} />
            {errors?.fxPass && <span style={{ color: "red" }}>{errors.fxPass}</span>}
          </div>
        </Form.Group>
      </div>

      <div className="d-flex justify-content-center gap-3 mt-3 mb-5">
        <Button className="fxAccountSubmit" onClick={() => { postForm(); }} disabled={loading?.formSubmit}>
          {loading?.formSubmit ? "Loading..." : "Submit"}
        </Button>
        {showForm &&
          <Button className="fxAccountSubmit" onClick={() => { setShowForm(false); }} disabled={loading?.formSubmit}>
            Cancel
          </Button>
        }
      </div>
    </>
  );
}
