import React, { useState, } from "react";
import { Button, Table } from "react-bootstrap";

const Convert = () => {
    const [open, setOpen] = React.useState(true);
    const [open1, setOpen1] = React.useState(false);
    const [AssetDetails, setAssetDetails] = useState(true);
    const [deposit, setDeposit] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const [transfer, setTransfer] = useState(false);
    const [convert, setConvert] = useState(false);
    const [bybit, setBybit] = useState(false);
    const [okx, setOkx] = useState(false);


    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const data = [

    ]
    return (
        <>
            <div>
                <div>
                    <p>
                        You can only redeem once within 24 hours. Running strategy does not support conversion
                    </p>
                </div>
                <div>
                    <p>
                        Please select an exchange
                    </p>
                </div>
                <div>
                    <div>
                        <div>
                            <Button
                                onClick={() => {
                                    setAssetDetails(true); setDeposit(false); setWithdraw(false);
                                    setTransfer(false); setConvert(false); setBybit(false); setOkx(false);
                                    handleClickOpen1();
                                }}
                                className={AssetDetails ? 'Binancebtn' : 'Binancebtn1'}
                            >Binance</Button>
                            <Button
                                onClick={() => {
                                    setAssetDetails(false); setDeposit(true); setWithdraw(false);
                                    setTransfer(false); setConvert(false); setBybit(false); setOkx(false);
                                    handleClickOpen1();
                                }}
                                className={deposit ? 'Binancebtn' : 'Binancebtn1'}>Huobi</Button>
                            <Button
                                onClick={() => {
                                    setAssetDetails(false); setDeposit(false); setWithdraw(true);
                                    setTransfer(false); setConvert(false); setBybit(false); setOkx(false);
                                    handleClickOpen1();
                                }}
                                className={withdraw ? 'Binancebtn' : 'Binancebtn1'}>Coinbase Pro</Button>
                            <Button
                                onClick={() => {
                                    setAssetDetails(false); setDeposit(false); setWithdraw(false);
                                    setTransfer(true); setConvert(false); setBybit(false); setOkx(false);
                                    handleClickOpen1();
                                }}
                                className={transfer ? 'Binancebtn' : 'Binancebtn1'}>Indodax</Button>
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    setAssetDetails(false); setDeposit(false); setWithdraw(false);
                                    setTransfer(false); setConvert(true); setBybit(false); setOkx(false);
                                    handleClickOpen1();
                                }}
                                className={convert ? 'Binancebtn' : 'Binancebtn1'}>Kraken</Button>
                            <Button
                                onClick={() => {
                                    setAssetDetails(false); setDeposit(false); setWithdraw(false);
                                    setTransfer(false); setConvert(false); setBybit(true); setOkx(false);
                                    handleClickOpen1();
                                }}
                                className={bybit ? 'Binancebtn' : 'Binancebtn1'}>Bybit</Button>
                            <Button
                                onClick={() => {
                                    setAssetDetails(false); setDeposit(false); setWithdraw(false);
                                    setTransfer(false); setConvert(false); setBybit(false); setOkx(true);
                                    handleClickOpen1();
                                }}
                                className={okx ? 'Binancebtn' : 'Binancebtn1'}>Okx</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <p>
                                        Estimated amount（USDT）
                                    </p>
                                    <p>
                                        0 USDT
                                    </p>
                                </div>
                                <Button>
                                    Flash redemption
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div >

                        <Table className="forexTable mb-5">
                            <thead
                                style={{
                                    minWidth: "900px",
                                    background:
                                        "linear-gradient(180.99deg, #2FF3FF -25%, #1E92AA 141.48%)",
                                }}
                            >
                                <tr>
                                    <th>
                                        <input type="checkbox" />
                                    </th>

                                    <th>
                                        Name
                                    </th>

                                    <th>
                                        Maximum turnable
                                    </th>
                                    <th>
                                        Convertible to USDT
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ background: "#161616" }}>
                                {data.map((values, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                {values.item}
                                            </td>

                                            <td>
                                                {values.item1}
                                            </td>
                                            <td>
                                                {values.item2}
                                            </td>
                                            <td>
                                                {values.item2}
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                        {
                            (data &&
                                data.length === 0 &&
                                data.length === undefined) ||
                            data.length === null ||
                            (data.length === 0 && 'Data Not Found')
                        }

                        {/* <div>
                                    <Dialog open={open} onClose={handleClose}>
                                        <DialogContent>
                                            <div>
                                                <img src="/images/Cards/mobi.png" alt="mobile card " />
                                                <p>Reminder</p>
                                            </div>
                                            <p>"Convert to USDT" allows you to convert all the remaining coins to USDT after all positions are fully closed</p>
                                            <div>
                                                <p>Please note: Conversion may only be done ONCE every 24H. When Starting/ Pausing/ Unfinished/ Waiting to be triggered, you will not be able to convert coins. All coins converted will be stored in your exchange.</p>
                                            </div>
                                            <Button fullWidth onClick={handleClose}>Sure</Button>
                                        </DialogContent>
                                    </Dialog>
                                </div> */}
                        {/* <div>
                                    <Dialog open={open1} onClose={handleClose1}>
                                        <DialogContent>
                                            <div>
                                                <p>More Actions Required</p>
                                                <img src="/images/Cards/dialogimg.png" alt="card dialog icon" />
                                                <p>You need to connect your Huobi account in order to proceed. Would you like to proceed to the API connection settings?</p>
                                            </div>
                                            <div style={{ display: 'flex', gap: '30px', paddingBottom: '30px' }}>
                                                <Button fullWidth onClick={handleClose1}>Later</Button>
                                                <Button fullWidth onClick={handleClose1}>Go Connect</Button>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Convert
