import React, { useState, useEffect, useContext } from "react";
import { Button, ButtonGroup, Form, Spinner, Table } from "react-bootstrap";
import './wallet.css';
import Withdraw from "./Withdraw";
import Deposit from "./Deposit";
import Transfer from "./Transfer";
import Convert from "./Convert";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
// import Pagination from "@material-ui/lab/Pagination";
import ApiConfig from "../../Configs/ApiConfig";
import MainContext from "../../Configs/Context/MainContext";
import { LuRefreshCw } from "react-icons/lu";
import { PiCopySimpleLight } from "react-icons/pi";
import { useLocation } from "react-router-dom";

const FuelWallet = () => {
  const token = localStorage.getItem("token");
  const context = useContext(MainContext);
  const location = useLocation();
  // const exchangeBalance = user?.exchangeBalance?.allExchangeTotal;
  const fuelBalance = context?.assetCount?.fuelAccountBalance?.total;
  const defaultTab = location?.state?.tabName ? location?.state?.tabName : "assetDetails";

  const [tabName, setTabName] = useState(defaultTab);
  const [trasactionType, setTrasactionType] = React.useState("ALL");
  const [activationType, setActivationType] = React.useState("ACTIVATION FEE");
  const [open, setOpen] = React.useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDateActivation, setTtoDateActivation] = useState(null);
  const [fromDateActivation, setFromDateActivation] = useState(null);
  const [isRotated, setIsRotated] = useState(false);
  const [revenueCount, setRevenueCount] = useState({});
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [count, setCount] = useState({
    count: null,
    count1: null,
  });
  const [ActivationHistory, setActivationHistory] = useState([]);
  const [totalAsset, setTotalAsset] = useState("");
  const [transaction, setTransaction] = useState("Transaction");
  const [page, setPage] = useState(1);
  const [pageCpunt, setPageCpunt] = useState(1);
  const [pagelist, setPageList] = useState(1);
  const [pageCpuntList, setPageCpuntList] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event) => {
    setTrasactionType(event.target.value);
    setPage(1);
  };

  const getRevenueDetails = async (values) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getRevenueDetails,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        const data = res.data.result;
        setRevenueCount(data);
        const total =
          res.data.result.totalActivationGain.getTeamTotal +
          res.data.result.directStrategyProfit.getTeamTotal +
          res.data.result.teamStrategyProfit.getTeamTotal +
          res.data.result.totalRevenueProfit.getTeamTotal;
        setTotalAsset(total);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getTransactionHistory = async (values) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.transactionHistoryData,
        headers: { token: token },
        params: {
          transacionType: trasactionType == "ALL" ? "" : trasactionType,
          fromDate: fromDate
            ? `${moment(fromDate).format("YYYY-MM-DD")}`
            : null,
          toDate: toDate ? `${moment(toDate).format("YYYY-MM-DD")}` : null,
          page: page,
          limit: 10,
        },
      });

      if (res.data.statusCode === 200) {
        setTransactionHistory(res.data.result.data);
        setPageCpunt(res.data.result.totalPages);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getActivationGainHistory = async (values) => {

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.directAndIndirectGain,
        headers: { token: token },
        params: {
          transacionType: activationType,
          fromDate: fromDateActivation
            ? `${moment(fromDateActivation).format("YYYY-MM-DD")}`
            : null,
          toDate: toDateActivation
            ? `${moment(toDateActivation).format("YYYY-MM-DD")}`
            : null,
          page: pagelist,

          limit: 10,
        },
      });
      if (res.data.statusCode === 200) {
        setActivationHistory(res.data.result.data);
        setPageCpuntList(res.data.result.totalPages);
      } else {
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (token) {
      getRevenueDetails();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getTransactionHistory();
    }
  }, [token, trasactionType, fromDate, toDate, page]);

  useEffect(() => {
    if (token) {
      getActivationGainHistory();
    }
  }, [token, activationType, fromDateActivation, toDateActivation, transaction]);

  const iconRotate = () => {
    getTransactionHistory();

    setIsRotated(!isRotated);
    context?.exchangeWallet()
      .then(() => {
        setIsRotated(false);
      });
  };

  const strategyProfitData = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.strategyProfit,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        setCount({
          count: res.data.result.todayProfit,
          count1: res.data.result.totalProfit,
        });
      } else {
        // toast.error("res.data.responseMessage");
      }
    } catch (error) {
      // toast.error(error.response.data.responseMessage);
    }
  };

  useEffect(() => {
    if (token) {
      strategyProfitData();
    }
  }, [token]);

  return (
    <>
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <h5>{tabName === "deposit" ? "Deposit" : tabName === "withdraw" ? "Withdraw" : tabName === "transfer" ? "Transfer" : "Fuel Fee Wallet"}</h5>
          <div className="walletBtns">
            <Button className={tabName === "assetDetails" ? 'active' : ''} onClick={() => { setTabName("assetDetails"); }}>History</Button>
            <Button className={tabName === "deposit" ? 'active' : ''} onClick={() => { setTabName("deposit"); }}>Deposit</Button>
            <Button className={tabName === "withdraw" ? 'active' : ''} onClick={() => { setTabName("withdraw"); }}>Withdraw</Button>
            <Button className={tabName === "transfer" ? 'active' : ''} onClick={() => { setTabName("transfer"); }}>Transfer To MT5</Button>
            {/* <Button disabled className={tabName === "convert" ? 'active' : ''} onClick={() => { setTabName("convert"); }}>Convert To USDT</Button> */}
          </div>
        </div>
        {tabName === "assetDetails" ? (
          <>
            <div>
              <div className="d-flex align-items-center justify-content-between mb-4">
                {isRotated ?
                  <div>
                    <Spinner size="sm" style={{ borderWidth: '2px' }} />
                  </div>
                  :
                  <>
                    <p>My Fuel Balance: <span className="fw-bold">$ {fuelBalance ? fuelBalance?.toFixed(4) : "0.00"}</span></p>
                    {/* <p>Total Activation reward: <span className="fw-bold">$ {revenueCount?.totalActivationGain?.getTeamTotal ? revenueCount?.totalActivationGain?.getTeamTotal?.toFixed(4) : "0.00"}</span></p> */}
                  </>
                }
                <div>
                  <Button className='refreshBtn' onClick={() => iconRotate()} disabled={isRotated}><LuRefreshCw color='#3DACF4' fontSize={'22px'} /> Refresh</Button>
                </div>
              </div>
            </div>

            {/* <div>
              <p>$ {count?.count1 ? count?.count1?.toFixed(4) : "0"}</p>
              <p>Total Revenue</p>
              <p>$ {count?.count ? count?.count?.toFixed(4) : "0"}</p>
              <p>Today's Revenue Profit</p>
            </div> */}

            {/* <div className="d-flex gap-3 mb-4">
              <div>
                <p>Select Transacion Type</p>
                <div>
                  <Form.Select
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={trasactionType}
                    onChange={handleChange}
                  >
                    <option value="ALL">All</option>
                    <option value="DEPOSIT">Deposit</option>
                    <option value="WITHDRAW">Withdraw</option>
                    <option value="TRANSFER">Transfer</option>
                    <option value="CREDIT">CREDIT</option>
                  </Form.Select>
                </div>
              </div>
              <div>
                <p>From</p>
                <div>
                  <Form.Control type="date" placeholder="DD/MM/YYYY"
                    value={fromDate}
                    onClick={(e) => e.target.showPicker()}
                    onChange={(e) => {
                      if ((new Date(e.target.value) > new Date(toDate)) || !toDate) {
                        setFromDate(e.target.value);
                        setToDate(e.target.value);
                        setPage(1);
                      } else {
                        setFromDate(e.target.value);
                        setPage(1);
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <p>To</p>
                <div>
                  <Form.Control type="date" placeholder="DD/MM/YYYY"
                    value={toDate}
                    onClick={(e) => e.target.showPicker()}
                    min={fromDate}
                    onChange={(e) => {
                      if (!fromDate) {
                        setFromDate(e.target.value);
                        setToDate(e.target.value);
                        setPage(1);
                      } else {
                        setToDate(e.target.value);
                        setPage(1);
                      }
                    }}
                  />
                </div>
              </div>
            </div> */}

            <div>
              <div>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : transactionHistory?.length === 0 ||
                  transactionHistory === undefined ? (
                  <div className="text-center">
                    <p>No Data Found</p>
                  </div>
                ) : (
                  <Table responsive className="forexTable mb-5">
                    <thead>
                      <tr>
                        <th>Sr. no.</th>

                        <th>From Address</th>
                        <th>User Details</th>
                        <th>To Address</th>
                        <th>Transaction Type</th>
                        <th>Quantity</th>
                        <th>Date & Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {transactionHistory?.map((values, index) => {
                        return (
                          <tr>
                            <td>{index + 1 + (page - 1) * 10}</td>

                            <td>
                              {typeof values.formAddress == "object" ?
                                values?.formAddress?.slice(0, 10)
                                : values?.formAddress?.slice(0, 10)}
                              {values.formAddress ? "..." : "--"}

                              {values.formAddress && (
                                <CopyToClipboard className='ms-1' text={values.formAddress} onCopy={() => { toast.success("Copied Successfully"); }}>
                                  <PiCopySimpleLight />
                                </CopyToClipboard>
                              )}
                            </td>
                            <td>{values.receiverNickName ? values.receiverNickName : "--"} | {values.receiverReferKey ? values.receiverReferKey : "--"}</td>
                            <td>
                              {typeof values.toAddress == "object" ? values?.toAddress?.slice(0, 10) : values?.toAddress?.slice(0, 10)}
                              {values.toAddress ? "..." : "--"}
                              {values.toAddress &&
                                <CopyToClipboard className='ms-1' text={values.toAddress} onCopy={() => { toast.success("Copied Successfully"); }}>
                                  <PiCopySimpleLight />
                                </CopyToClipboard>
                              }
                            </td>
                            <td>{values.transactionType ? values.transactionType : "--"}</td>
                            <td>{values?.amount ? values.amount?.toFixed(4) : "0"}</td>
                            <td>{moment(values.createdAt).format("Do MMM YYYY, h:mm a")}</td>
                            <td style={{ color: values.status === "COMPLETED" ? "#00C64F" : "#FFA800" }}>{values.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {transactionHistory?.length === 0 ||
                  transactionHistory === undefined ? (
                  ""
                ) : (
                  <div>
                    {/* <Pagination
                              count={pageCpunt}
                              page={page}
                              onChange={(e, v) => setPage(v)}
                            /> */}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : ""}
        {tabName === "deposit" ? (
          <Deposit />
        ) : (
          ""
        )}
        {tabName === "withdraw" ? (
          <Withdraw />
        ) : (
          ""
        )}
        {tabName === "transfer" ? (
          <Transfer />
        ) : (
          ""
        )}
        {tabName === "convert" ? (
          <Convert />
        ) : (
          ""
        )}
      </div >
    </>
  );
};

export default FuelWallet;
