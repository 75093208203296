import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment/moment";
import "./auth.css";
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import ApiConfig from "../../Configs/ApiConfig";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ROUTES from "../../Configs/Routes";

function Login() {
  const token = localStorage.getItem('token');
  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [isloading, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const fetchDeviceInfo = async () => {
    try {
      const res = await Axios.get("https://api.ipify.org?format=json");
      const ip = res.ip;
      const locationResponse = await fetch(`https://ipapi.co/${ip}/json/`);
      const locationData = await locationResponse.json();
      const deviceName = navigator.userAgent || ""; // Use an empty string as fallback if navigator.userAgent is not available
      const deviceInfo = {
        deviceName,
        ipAddress: ip,
        location: locationData,
      };

      setDeviceInfo(deviceInfo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDeviceInfo();
  }, []);

  // useEffect(() => {
  //   getData();
  //   window.localStorage.clear();
  // }, []);

  const formInitialSchema = isRememberMe
    ? {
      email: "",
      password: "",
    }
    : {
      email: window.sessionStorage.getItem("email")
        ? window.sessionStorage.getItem("email")
        : "",
      password: window.localStorage.getItem("password")
        ? window.localStorage.getItem("password")
        : "",
    };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if ((formData.email === "" || formData.email === undefined) && (formData.password === "" || formData.password === undefined)) {
      setErrors({ ...errors, email: "Email is required.", password: "Password is required." });
      return;
    } else if (formData.email === "" || formData.email === undefined) {
      setErrors({ ...errors, email: "Email is required." });
      return;
    } else if (formData.password === "" || formData.password === undefined) {
      setErrors({ ...errors, password: "Password is required." });
      return;
    } else {
      setErrors({
        email: "",
        password: "",
      });
    }

    sessionStorage.setItem("email", formData.email);

    setLoader(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.login,
        data: {
          email: formData.email,
          password: formData.password,
          // deviceName: deviceInfo.deviceName,
          // ipAddress: deviceInfo.ipAddress,
          // location: `${deviceInfo.location.city},${deviceInfo.location.region},${deviceInfo.location.country_name}`,
        },
      });

      if (res?.data?.statusCode === 200) {
        toast.success("You are successfully logged in.");
        window.localStorage.setItem("token", res?.data?.result?.token);
        context?.setJustLogin(true);
        navigate(ROUTES.LOGIN);
        // context?.exchangeWallet();
        // context?.advertismentBanner();
        // context?.useSetStateAfterDelay();
      } else {
        toast.warn(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log('first', error)
      setLoader(false);
      if (error?.response?.data?.responseMessage) {
        toast.error(error?.response?.data?.responseMessage);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("key");
    let email = urlParams.get("id");
    if (email) {
      setShowLogin(true);
      email = email.replace(/\s/g, '+')
      setFormData({ email: email, password: '' });
    }

    const getAdmin = async () => {
      try {
        setLoader(true);
        const res = await Axios.post(ApiConfig.login, {
          email: email,
          password: key,
          loginBy: 0
        });
        toast.success("You are successfully logged in.");
        window.localStorage.setItem("token", res.data.result.token);
        context?.setJustLogin(true);
        navigate(ROUTES.DASHBOARD);
        // context?.exchangeWallet();
        // context?.advertismentBanner();
        // context?.useSetStateAfterDelay();
      }
      catch (err) {
        setLoader(false);
      }
    }

    if (key && email) {
      getAdmin();
    }
    // Now you can use the refCode variable wherever you need it
  }, []);

  // useEffect(() => {
  //   const { detect } = require("detect-browser");
  //   const browser = detect();
  //   if (browser) {
  //     setDeviceBowser(browser?.name);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (
  //     window.localStorage.getItem("password") ||
  //     window.sessionStorage.getItem("email")
  //   ) {
  //     setIsRememberMe(true);
  //   } else {
  //     setIsRememberMe(false);
  //   }
  // }, [
  //   window.sessionStorage.getItem("email"),
  //   window.localStorage.getItem("password"),
  // ]);

  function rememberMe(email, password) {
    if (!isRememberMe) {
      setIsRememberMe(true);
      window.sessionStorage.setItem("email", email);
      window.localStorage.setItem("password", password);
    } else {
      setIsRememberMe(false);
      window.sessionStorage.removeItem("email");
      window.localStorage.removeItem("password");
    }
  }

  // for resetting the otp timer
  useEffect(() => {
    context?.setEndtime(moment().add(0, "m").unix());
  }, []);

  useEffect(() => {
    if (token) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [token])

  const showLoginFields = () => {
    setShowLogin(!showLogin);
  };

  return (
    <>
      <div className="authDiv">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="bannerTxt my-lg-4 me-xxl-5">
                <h2>Welcome Back To <span className="d-block">FOREX AI TRADE</span></h2>
                <p className="pe-xxl-5 me-xxl-5">A Workspace to Over 1 Million Happy User's Worldwide.</p>
              </div>
              <div className="authImg">
                <img src='/images/user/authImg.png' alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="loginDiv">
                <Form onSubmit={handleFormSubmit}>
                  <h2 className="authTitle mb-3">Login</h2>
                  <Row className="gx-2 gy-3">
                    <Col sm={12}>
                      {/* <label>Email Address <span style={{ color: "red" }}>*</span></label> */}
                      <Form.Control placeholder="Enter An Email Address" type="email" maxLength={256} value={formData.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} />
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.email || ''}</p>
                    </Col>

                    <Col sm={12}>
                      {/* <label>Password <span style={{ color: "red" }}>*</span></label> */}
                      <InputGroup>
                        <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter Your Password" onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }} value={formData.password} maxLength={20} />
                        <InputGroup.Text>
                          <div onClick={() => setShowPassword(!showPassword)} style={{ fontSize: '22px', color: '#838383' }}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </InputGroup.Text>
                      </InputGroup>
                      <p style={{ fontSize: "12px", color: 'red', }}>{errors.password || ''}</p>
                    </Col>

                    <Col sm={12} className="d-flex justify-content-between gap-2">
                      <Form.Check type="checkbox" name="checkedC" checked={isRememberMe} onChange={(e) => { setIsRememberMe(e.target.checked) }} onClick={() => rememberMe(formData.email, formData.password)} label="Remember me" />
                      <Link to={ROUTES.FORGET_PWD} style={{ color: "#3DACF4" }}>Forgot Password?</Link>
                    </Col>

                    <Col sm={12}>
                      <Button type="submit" className="loginBtn" disabled={isloading}>
                        Login {isloading && <Spinner size="sm" />}
                      </Button>

                      <p className="text-center mt-2">Don't have an Account? <Link to={ROUTES.SIGNUP} style={{ color: "#3DACF4" }}>SignUp</Link></p>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
